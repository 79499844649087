import React, { useCallback, useRef, useState } from 'react';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar } from '@devexpress/dx-react-grid-material-ui';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { Button, Checkbox, IconButton, Tooltip, Typography } from '@mui/material';
import { Add, Delete, Publish, RemoveCircle } from '@mui/icons-material';
import { lightGreen, red } from '@mui/material/colors';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { GroupLicenseTable } from '../../../models/GroupLicenseTable';
import { formatEuropeanDateTime } from '../../../helpers/date-management';
import { getApiToken } from '../../../helpers/storage-management';
import { white } from '../../../styles/colors';
import { api } from '../../../api';
import { saveExcelFile } from '../../../helpers/tables/common';
import { LoraDeviceModel } from '../../../models/lora/LoraDeviceModel';
import { useTranslation } from 'react-i18next';

export const LoraDevicesTable = ({ rows, selection, setSelection, assignBssdSensorLicense, groups, onDeleted }
    : { rows: LoraDeviceModel[], selection: any[], setSelection: any, assignBssdSensorLicense: any, groups: GroupLicenseTable[], onDeleted: any }) => {

    const [pageSize, setPageSize] = React.useState(20);
    const [currentPage, setCurrentPage] = React.useState(0);

    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();

    const {t} = useTranslation();

    let columns = [
        {
            name: LoraDeviceModel.Columns.groupName,
            title: "Einheit Name",
            getCellValue: (row: LoraDeviceModel) => row.groupId ? groups.find(x => x.id == row.groupId)?.notificationName : "Keines zugewiesen",
        },
        {
            name: LoraDeviceModel.Columns.id,
            title: "Device Id",
            getCellValue: (row: LoraDeviceModel) => row.deviceId,
        },
        {
            name: LoraDeviceModel.Columns.creationTimestamp,
            title: "Erstellungszeitpunkt",
            getCellValue: (row: LoraDeviceModel) => formatEuropeanDateTime(row.creationTimestamp),
        }
    ];

    columns = [{
        name: LoraDeviceModel.Columns.deleteButton,
        title: t("myUnitsSettings.edit"),
        getCellValue: (row: LoraDeviceModel) => row.groupId ?
            <Button
                variant="contained"
                style={{ backgroundColor: red[900], color: white }}
                onClick={async () => {
                    const tempToken = getApiToken(history);
                    await api.UnAssignLoraDeviceToGroupByIdAsync(row.groupId, row.deviceId, tempToken, enqueueSnackbar);
                    onDeleted();
                }}
                endIcon={<RemoveCircle />}
            >
                Einheit Zuweisung Löschen
            </Button> : "Sensor keiner Einheit zugewiesen"
    } as any, ...columns]


    const Cell = (props: any) => {
        const { column, row, value } = props;
        if (column.name === LoraDeviceModel.Columns.assignSensorLicense && row.groupId) {
            return (
                <Table.Cell {...props}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Publish />}
                        onClick={() => {
                            assignBssdSensorLicense(row.groupId);
                        }}
                    >
                        Lizenzen zuweisen
                    </Button>
                </Table.Cell>
            )
        }
        return <Table.Cell {...props} />;
    };

    const exporterRef: any = useRef(null);

    const startExport = useCallback((options) => {
        exporterRef.current.exportGrid(options);
    }, [exporterRef]);

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Lorasensor');
        });
    };

    let defaultColumnWidths = React.useRef([{ columnName: LoraDeviceModel.Columns.deleteButton, width: 300 }, ...columns.map(el => { return { columnName: el.name, width: 220 } })]);
    const [defaultHiddenColumnNames] = React.useState([]);

    const SelectionCell = (props: any) => {
        const { column, row, value } = props;
        if (row.groupId) return (
            <td style={{ paddingLeft: 4, textAlign: 'right', overflow: 'visible', paddingRight: 0 }}>
                <span style={{ padding: 8, marginTop: -1, marginBottom: -1 }}>
                    <Checkbox checked={true} disabled />
                </span>
            </td>
        );
        return <TableSelection.Cell {...props} />;
    };

    const TableRow = ({ row, ...restProps }: any) => (
        <Table.Row
            {...restProps}
            // eslint-disable-next-line no-alert
            style={{
                cursor: 'pointer',
                backgroundColor: row.groupId && lightGreen[50]
            }}
        />
    );

    return (
        <div>
            <Grid
                rows={rows}
                columns={columns}
                getRowId={(row) => row.id}
            >

                <FilteringState
                />
                <IntegratedFiltering />

                <SortingState
                //            sorting={[{ columnName: MotionTableEntry.Columns.createdTimestamp, direction: 'desc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState selection={selection}
                    onSelectionChange={(ids: any[]) => {
                        setSelection([...ids]);
                    }}
                />
                <IntegratedPaging />
                <IntegratedSelection />
                <Table rowComponent={TableRow} cellComponent={Cell}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths.current} />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility
                    defaultHiddenColumnNames={defaultHiddenColumnNames}
                />
                <TableFilterRow />
                <TableColumnVisibility
                />
                <Toolbar />
                <ColumnChooser />
                <TableSelection rowComponent={TableRow} cellComponent={SelectionCell} />
                <PagingPanel pageSizes={[10, 20, 30, 0]} />
                <ExportPanel startExport={startExport} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={rows}
                columns={columns}
                selection={selection}
                onSave={onSave}
            />
        </div>
    );
};
