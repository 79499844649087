import React, { useCallback, useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import {
    EditingState,
    FilteringState,
    GroupingState,
    IntegratedFiltering,
    IntegratedGrouping,
    IntegratedSorting,
    SearchState,
    SortingState,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableGroupRow,
    TableFilterRow,
    TableColumnResizing,
    ColumnChooser,
    TableColumnVisibility,
    Toolbar,
    TableEditColumn,
    DragDropProvider,
    TableColumnReordering,
    ExportPanel,
    SearchPanel,
} from '@devexpress/dx-react-grid-material-ui';
import { GroupSettings } from '../../models/GroupSettings';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { UserGroupSettingsTemplate } from '../../models/UserGroupSettingsTemplate';
import { PopupEditingGroupSettingsTemplate, PopupGroupSettingsTemplate } from '../popupEdit/GroupSettingsTemplate';
import { api } from '../../api';
import { Button, Checkbox, FormControlLabel, IconButton, TableCell, Typography } from '@mui/material';
import { GroupSettingsMappingKeys, mapGroupSettings } from '../../helpers/group-settings-management';
import { GroupSelectionTemplateSettingsLoadDialog } from '../dialogs/GroupSelectionTemplateSettingsLoad';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { GroupSelectionLoadTemplateSettings } from '../../models/GroupSelectionLoadTemplateSettings';
import { useSnackbar } from 'notistack';
import { ConfirmationDialog } from '../dialogs/ConfirmationDialog';
import { saveExcelFile } from '../../helpers/tables/common';
import { Add, Delete, Edit } from '@mui/icons-material';
import { GroupLicenseTable } from '../../models/GroupLicenseTable';
import { useTranslation } from 'react-i18next';



const CheckboxCell = (props: any) => {
    const { value, style, ...restProps } = props;
    return (
        <Table.Cell
            {...restProps}
            style={{
                ...style
            }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={value}
                        name="belegt"
                        color="primary"
                    />
                }
                label=""
            />
        </Table.Cell>
    )
};

const LoadGroupSettingsCell = (props: any) => {
    const { value, style, setOpenSettingsLoad, setCurrentSelectedGroupSettingsLoadRow, row, ...restProps } = props;
    return (
        <Table.Cell
            {...restProps}
            style={{
                ...style
            }}>
            <Button size="medium" style={{ marginRight: 'auto' }}
                startIcon={<GroupAddIcon />} variant={'contained'} onClick={() => {
                    setOpenSettingsLoad(true);
                    setCurrentSelectedGroupSettingsLoadRow(row);
                }} color="primary">
                Auf Einheiten laden
            </Button>
        </Table.Cell>
    )
};


const Cell = (props: any) => {
    const { column } = props;
    if (column.name === 'sensorControlIsAmountTriggerEnabled' || column.name === 'sensorControlIsSensorEnabled' || column.name === 'isFeedbackControlEnabled' || column.name === 'isSumDisplayEnabled'
        || column.name === 'intervalControlIsIntervalEnabled' || column.name === 'intervalControlIsSpecificTimeEnabled' || column.name === 'isIntervalControlEnabled') {
        return <CheckboxCell {...props} />;
    } else if (column.name == 'loadGroupSettings') {
        return <LoadGroupSettingsCell {...props} />
    }
    return <Table.Cell {...props} />;
};

const FilterCellCheck = ({ filter, onFilter, classes }: { filter: any, onFilter: any, classes: any }) => (
    <TableCell>
        <FormControlLabel
            control={
                <Checkbox
                    onChange={e => {
                        onFilter({ value: e.target.checked });
                    }}
                    color="primary"
                />
            }
            label=""
        />
    </TableCell>
);

const FilterCell = (props: any) => {
    const { column } = props;
    if (column.name === 'sensorControlIsAmountTriggerEnabled' || column.name === 'sensorControlIsSensorEnabled' || column.name === 'isFeedbackControlEnabled' || column.name === 'isSumDisplayEnabled'
        || column.name === 'intervalControlIsIntervalEnabled' || column.name === 'intervalControlIsSpecificTimeEnabled' || column.name === 'isIntervalControlEnabled') {
        return <FilterCellCheck {...props} />;
    }
    return <TableFilterRow.Cell {...props} />;
};

export const GroupSettingsTemplateTable = ({ userGroupSettings, setUserGroupSettings, token, groups, loadData }
    : { userGroupSettings: UserGroupSettingsTemplate[], setUserGroupSettings: any, token: string, groups: GroupLicenseTable[], loadData: any }) => {

    const { enqueueSnackbar } = useSnackbar();
    const [confirmationDialogVisible, setConfirmationDialogVisible] = React.useState(false);
    const [deletedRowId, setDeletedRowId] = React.useState("");

    const {t} = useTranslation();

    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `Vorlagen`);
        });
    };

    const [columns] = useState([
        { name: 'loadGroupSettings', title: 'Lade Vorlage auf Einheiten' },
        { name: 'name', title: 'Vorlage Name' },
        { name: 'id', title: 'Einheit ID' },
        { name: 'objectName', title: t("object-storage-single-eval.object"), getCellValue: (row: UserGroupSettingsTemplate) => (row.locationInformation?.objectName) },
        { name: 'levelName', title: 'Ebene', getCellValue: (row: UserGroupSettingsTemplate) => (row.locationInformation?.levelName) },
        { name: 'roomName', title: 'Kunde', getCellValue: (row: UserGroupSettingsTemplate) => (row.locationInformation?.roomName) },
        { name: 'notificationName', title: 'Einheit Name', getCellValue: (row: UserGroupSettingsTemplate) => (row.locationInformation?.notificationName) },
        { name: 'toiletType', title: 'Raumart', getCellValue: (row: UserGroupSettingsTemplate) => (row.locationInformation?.toiletType) },
        { name: 'locationPassword', title: 'Passwort', getCellValue: (row: UserGroupSettingsTemplate) => (row.locationInformation?.password) },
        { name: 'cleaningOperations', title: 'Reinigungsaufgaben', getCellValue: (row: UserGroupSettingsTemplate) => (row.cleaningOperations?.cleaningOperations.map(el => el.text).join(', ')) },
        { name: 'cleaningEmail', title: 'Reinigungsemail', getCellValue: (row: UserGroupSettingsTemplate) => (row.notification?.cleaningEmail) },
        { name: 'incidentReportEmail', title: 'Vorfallmeldungemail', getCellValue: (row: UserGroupSettingsTemplate) => (row.notification?.incidentReportEmail) },
        { name: GroupSettingsMappingKeys.notificationTelegramPhoneNumber, title: 'Telefonnummer', getCellValue: (row: UserGroupSettingsTemplate) => (row?.notification?.notificationTelegramGroup?.phoneNumber) },
        { name: 'telegramGroupTitle', title: 'Telegramgruppen Titel', getCellValue: (row: UserGroupSettingsTemplate) => (row.notification?.notificationTelegramGroup.title) },
        { name: 'whatsAppGroupName', title: 'WhatsAppgruppen Titel', getCellValue: (row: UserGroupSettingsTemplate) => (row.notification?.notificationInformationWhatsAppGroup.whatsAppGroupName) },
        { name: 'usernames', title: 'Usernames', getCellValue: (row: UserGroupSettingsTemplate) => (row.users?.users.map(el => el.username).join(', ')) },
        { name: 'sensorControlIsSensorEnabled', title: 'Sensor Aktiviert', getCellValue: (row: UserGroupSettingsTemplate) => (row.sensorControl?.isSensorControlEnabled) },
        { name: 'sensorControlAmountTrigger', title: 'Sensor Anzahl Durchgänge Auslöser', getCellValue: (row: UserGroupSettingsTemplate) => (row.sensorControl?.amountTrigger) },
        { name: 'sensorControlIsAmountTriggerEnabled', title: 'Sensor Anzahl Durchgänge Aktiviert', getCellValue: (row: UserGroupSettingsTemplate) => (row.sensorControl?.isAmountTriggerEnabled) },
        {
            name: 'sensorItems', title: 'Sensors', getCellValue: (row: UserGroupSettingsTemplate) => {
                if (row.sensorControl && row.sensorControl.sensors && row.sensorControl.sensors.length > 0) {
                    return row.sensorControl?.sensors.map(el => el.id).join(', ');
                } else {
                    return "Keine Sensoren registriert"
                }
            }
        },

        { name: 'feedbackControlAmountTriggerBad', title: 'Auslösung bei Anzahl negativer Feedbacks', getCellValue: (row: UserGroupSettingsTemplate) => (row.feedbacks?.amountTriggerBad) },
        { name: 'feedbackControlAmountTriggerOk', title: 'Auslösung bei Anzahl neutraler Feedbacks', getCellValue: (row: UserGroupSettingsTemplate) => (row.feedbacks?.amountTriggerOk) },
        { name: 'feedbackControlAmountTriggerGood', title: 'Auslösung bei Anzahl positiver Feedbacks', getCellValue: (row: UserGroupSettingsTemplate) => (row.feedbacks?.amountTriggerGood) },
        { name: 'isFeedbackControlEnabled', title: 'Feedback Aktiviert', getCellValue: (row: UserGroupSettingsTemplate) => (row.feedbacks?.isFeedbackControlEnabled) },
        { name: 'isSumDisplayEnabled', title: 'Summe Feedback Aktiviert', getCellValue: (row: UserGroupSettingsTemplate) => (row.feedbacks?.isSumDisplayEnabled) },
        { name: 'intervalControlStartTime', title: 'Startzeit Intervall', getCellValue: (row: UserGroupSettingsTemplate) => (row.intervalControl?.startTime) },
        { name: 'intervalControlEndTime', title: 'Endzeit Intervall', getCellValue: (row: UserGroupSettingsTemplate) => (row.intervalControl?.endTime) },
        { name: 'intervalControlRepeatDuration', title: 'Wiederholung in Min Serviceauslösung', getCellValue: (row: UserGroupSettingsTemplate) => (row.intervalControl?.repeatDuration) },
        { name: 'intervalControlDeviationTime', title: 'Abweichungszeit', getCellValue: (row: UserGroupSettingsTemplate) => (row.intervalControl?.deviationTime) },
        { name: 'intervalControlSpecificTimes', title: 'Spezifische Zeiten', getCellValue: (row: UserGroupSettingsTemplate) => (row.intervalControl?.specificTimes.map(el => el.specificTime).join(', ')) },
        { name: 'isIntervalControlEnabled', title: 'Interval Control Aktiviert', getCellValue: (row: UserGroupSettingsTemplate) => (row.intervalControl?.isIntervalControlEnabled) },
        { name: 'isMaterialConsumptionEnabled', title: 'Materialverbrauch Aktiviert', getCellValue: (row: UserGroupSettingsTemplate) => (row.materialConsumptions?.isMaterialConsumptionEnabled) },
        { name: 'materialConsumptionsItems', title: 'Materialverbrauch', getCellValue: (row: UserGroupSettingsTemplate) => (row.materialConsumptions?.materialConsumptions.map(el => el.text).join(', ')) },
        { name: 'isIncidentReportEnabled', title: 'Vorfallmeldung Aktiviert', getCellValue: (row: UserGroupSettingsTemplate) => (row.incidentReports?.isIncidentReportEnabled) },
        { name: 'incidentReportItems', title: 'Vorfallmeldungen', getCellValue: (row: UserGroupSettingsTemplate) => (row.incidentReports?.incidentReports.map(el => el.text).join(', ')) },
    ]);
    const [defaultColumnWidths] = useState([
        { columnName: 'loadGroupSettings', width: 250 },
        { columnName: 'id', width: 180 },
        { columnName: 'name', width: 180 },
        { columnName: 'objectName', width: 180 },
        { columnName: 'levelName', width: 180 },
        { columnName: 'roomName', width: 240 },
        { columnName: 'notificationName', width: 240 },
        { columnName: 'toiletType', width: 240 },
        { columnName: 'locationPassword', width: 0 },
        { columnName: 'cleaningOperations', width: 240 },
        { columnName: 'cleaningEmail', width: 200 },
        { columnName: 'incidentReportEmail', width: 200 },
        { columnName: GroupSettingsMappingKeys.notificationTelegramPhoneNumber, width: 200 },
        { columnName: 'telegramGroupTitle', width: 200 },
        { columnName: 'whatsAppGroupName', width: 200 },
        { columnName: 'usernames', width: 200 },
        { columnName: 'sensorControlIsSensorEnabled', width: 200 },
        { columnName: 'sensorControlAmountTrigger', width: 200 },
        { columnName: 'sensorControlIsAmountTriggerEnabled', width: 200 },
        { columnName: 'sensorItems', width: 200 },
        { columnName: 'feedbackControlAmountTriggerBad', width: 200 },
        { columnName: 'feedbackControlAmountTriggerOk', width: 200 },
        { columnName: 'feedbackControlAmountTriggerGood', width: 200 },
        { columnName: 'isFeedbackControlEnabled', width: 200 },
        { columnName: 'isSumDisplayEnabled', width: 200 },
        { columnName: 'intervalControlIsIntervalEnabled', width: 200 },
        { columnName: 'intervalControlStartTime', width: 200 },
        { columnName: 'intervalControlEndTime', width: 200 },
        { columnName: 'intervalControlRepeatDuration', width: 200 },
        { columnName: 'intervalControlDeviationTime', width: 200 },
        { columnName: 'intervalControlIsSpecificTimeEnabled', width: 200 },
        { columnName: 'intervalControlSpecificTimes', width: 200 },
        { columnName: 'isIntervalControlEnabled', width: 200 },
        { columnName: 'isMaterialConsumptionEnabled', width: 200 },
        { columnName: 'materialConsumptionsItems', width: 200 },
        { columnName: 'isIncidentReportEnabled', width: 200 },
        { columnName: 'incidentReportItems', width: 200 }
    ]);

    const [editingColumnExtensions] = useState([
        {
            columnName: GroupSettingsMappingKeys.isDeviceRestartEnabled,
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, GroupSettingsMappingKeys.isDeviceRestartEnabled, value)),
        },
        {
            columnName: GroupSettingsMappingKeys.isNavigationBarEnabled,
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, GroupSettingsMappingKeys.isNavigationBarEnabled, value)),
        },
        {
            columnName: GroupSettingsMappingKeys.isExecuteManuellOfflineEnabled,
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, GroupSettingsMappingKeys.isExecuteManuellOfflineEnabled, value)),
        },
        {
            columnName: GroupSettingsMappingKeys.isUploadOfflineDataEnabled,
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, GroupSettingsMappingKeys.isUploadOfflineDataEnabled, value)),
        },
        {
            columnName: GroupSettingsMappingKeys.isExecuteOfflineDataNowEnabled,
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, GroupSettingsMappingKeys.isExecuteOfflineDataNowEnabled, value)),
        },
        {
            columnName: 'objectName',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'objectName', value)),
        },
        {
            columnName: 'levelName',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'levelName', value)),
        },
        {
            columnName: 'roomName',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'roomName', value)),
        },
        {
            columnName: 'notificationName',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'notificationName', value)),
        },
        {
            columnName: 'areaSize',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'areaSize', value)),
        },
        {
            columnName: 'toiletType',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'toiletType', value)),
        },
        {
            columnName: 'locationPassword',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'locationPassword', value)),
        },
        {
            columnName: 'localPassword',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'localPassword', value)),
        },
        {
            columnName: 'deviceRestartTime',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'deviceRestartTime', value)),
        },
        {
            columnName: 'isDeviceRestartTimeEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isDeviceRestartTimeEnabled', value)),
        },
        {
            columnName: 'isLoginQrCodeEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isLoginQrCodeEnabled', value)),
        },
        {
            columnName: 'cleaningOperations',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'cleaningOperations', value);
            },
        },
        {
            columnName: 'cleaningOperationsAdd',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'cleaningOperationsAdd', value);
            },
        },
        {
            columnName: 'cleaningOperationsAddMultiple',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'cleaningOperationsAddMultiple', value);
            },
        },
        {
            columnName: 'cleaningOperationsAddMultipleOld',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'cleaningOperationsAddMultipleOld', value);
            },
        },
        {
            columnName: 'cleaningOperationsRemove',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'cleaningOperationsRemove', value);
            },
        },
        {
            columnName: 'serviceWorkingModeType',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'serviceWorkingModeType', value)),
        },
        //--------------------------------------- Notifications ---------------------------------------------
        {
            columnName: 'isMissedServiceMotionNotificationEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isMissedServiceMotionNotificationEnabled', value)),
        },
        {
            columnName: 'isMissedServiceFeedbackNotificationEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isMissedServiceFeedbackNotificationEnabled', value)),
        },
        {
            columnName: 'isMissedServiceIntervalNotificationEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isMissedServiceIntervalNotificationEnabled', value)),
        },
        {
            columnName: 'isMotionNotificationEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isMotionNotificationEnabled', value)),
        },
        {
            columnName: 'isFeedbackNotificationEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isFeedbackNotificationEnabled', value)),
        },
        {
            columnName: 'isIntervalNotificationEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isIntervalNotificationEnabled', value)),
        },
        {
            columnName: 'isServiceNotificationEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isServiceNotificationEnabled', value)),
        },
        {
            columnName: 'cleaningEmail',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'cleaningEmail', value)),
        },
        {
            columnName: 'cleaningEmailIsEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'cleaningEmailIsEnabled', value)),
        },
        {
            columnName: 'isOnlineStatusEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isOnlineStatusEmailEnabled', value)),
        },
        {
            columnName: 'isOnlineStatusWarnEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isOnlineStatusWarnEmailEnabled', value)),
        },
        {
            columnName: 'onlineStatusEmail',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'onlineStatusEmail', value)),
        },
        {
            columnName: 'incidentReportEmail',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'incidentReportEmail', value)),
        },
        {
            columnName: 'isIncidentReportEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isIncidentReportEmailEnabled', value)),
        },
        {
            columnName: GroupSettingsMappingKeys.notificationTelegramPhoneNumber,
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, GroupSettingsMappingKeys.notificationTelegramPhoneNumber, value)),
        },
        {
            columnName: 'missedServiceEmail',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'missedServiceEmail', value)),
        },
        {
            columnName: 'isMissedServiceEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isMissedServiceEmailEnabled', value)),
        },
        {
            columnName: 'freeTextIncidentEmail',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'freeTextIncidentEmail', value)),
        },
        {
            columnName: 'isFreeTextIncidentEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isFreeTextIncidentEmailEnabled', value)),
        },
        {
            columnName: 'feedbackQuestionEmail',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'feedbackQuestionEmail', value)),
        },
        {
            columnName: 'isFeedbackQuestionEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isFeedbackQuestionEmailEnabled', value)),
        },
        {
            columnName: 'freeTextFeedbackEmail',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'freeTextFeedbackEmail', value)),
        },
        {
            columnName: 'isFreeTextFeedbackEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isFreeTextFeedbackEmailEnabled', value)),
        },
        {
            columnName: 'frequencyAutoEmail',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'frequencyAutoEmail', value)),
        },
        {
            columnName: 'isFrequencyDailyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isFrequencyDailyAutoEmailEnabled', value)),
        },
        {
            columnName: 'isFrequencyWeeklyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isFrequencyWeeklyAutoEmailEnabled', value)),
        },
        {
            columnName: 'isFrequencyMonthlyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isFrequencyMonthlyAutoEmailEnabled', value)),
        },


        {
            columnName: 'serviceTriggerAutoEmail',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'serviceTriggerAutoEmail', value)),
        },
        {
            columnName: 'isServiceTriggerAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isServiceTriggerAutoEmailEnabled', value)),
        },
        {
            columnName: 'isServiceTriggerDailyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isServiceTriggerDailyAutoEmailEnabled', value)),
        },
        {
            columnName: 'isServiceTriggerWeeklyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isServiceTriggerWeeklyAutoEmailEnabled', value)),
        },
        {
            columnName: 'isServiceTriggerMonthlyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isServiceTriggerMonthlyAutoEmailEnabled', value)),
        },


        {
            columnName: 'onlineStatusAutoEmail',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'onlineStatusAutoEmail', value)),
        },
        {
            columnName: 'isOnlineStatusAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isOnlineStatusAutoEmailEnabled', value)),
        },
        {
            columnName: 'isServiceTriggerDailyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isServiceTriggerDailyAutoEmailEnabled', value)),
        },
        {
            columnName: 'isOnlineStatusWeeklyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isOnlineStatusWeeklyAutoEmailEnabled', value)),
        },
        {
            columnName: 'isOnlineStatusMonthlyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isOnlineStatusMonthlyAutoEmailEnabled', value)),
        },


        {
            columnName: 'hitlistAutoEmail',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'hitlistAutoEmail', value)),
        },
        {
            columnName: 'isHitlistDailyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isHitlistDailyAutoEmailEnabled', value)),
        },
        {
            columnName: 'isHitlistWeeklyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isHitlistWeeklyAutoEmailEnabled', value)),
        },
        {
            columnName: 'isHitlistMonthlyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isHitlistMonthlyAutoEmailEnabled', value)),
        },

        {
            columnName: 'cockpitAutoEmail',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'cockpitAutoEmail', value)),
        },
        {
            columnName: 'isCockpitDailyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isCockpitDailyAutoEmailEnabled', value)),
        },
        {
            columnName: 'isCockpitWeeklyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isCockpitWeeklyAutoEmailEnabled', value)),
        },
        {
            columnName: 'isCockpitMonthlyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isCockpitMonthlyAutoEmailEnabled', value)),
        },

        {
            columnName: 'feebackAutoEmail',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'feebackAutoEmail', value)),
        },
        {
            columnName: 'isFeedbackAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isFeedbackAutoEmailEnabled', value)),
        },
        {
            columnName: 'isFeedbackDailyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isFeedbackDailyAutoEmailEnabled', value)),
        },
        {
            columnName: 'isFeedbackWeeklyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isFeedbackWeeklyAutoEmailEnabled', value)),
        },
        {
            columnName: 'isFeedbackMonthlyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isFeedbackMonthlyAutoEmailEnabled', value)),
        },
        {
            columnName: 'servicesAutoEmail',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'servicesAutoEmail', value)),
        },
        {
            columnName: 'isServicesAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isServicesAutoEmailEnabled', value)),
        },
        {
            columnName: 'isServicesDailyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isServicesDailyAutoEmailEnabled', value)),
        },
        {
            columnName: 'isServicesWeeklyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isServicesWeeklyAutoEmailEnabled', value)),
        },
        {
            columnName: 'isServicesMonthlyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isServicesMonthlyAutoEmailEnabled', value)),
        },
        {
            columnName: 'qualityCheckAutoEmail',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'qualityCheckAutoEmail', value)),
        },
        {
            columnName: 'isQualityCheckAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isQualityCheckAutoEmailEnabled', value)),
        },
        {
            columnName: 'isQualityCheckDailyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isQualityCheckDailyAutoEmailEnabled', value)),
        },
        {
            columnName: 'isQualityCheckWeeklyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isQualityCheckWeeklyAutoEmailEnabled', value)),
        },
        {
            columnName: 'isQualityCheckMonthlyAutoEmailEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isQualityCheckMonthlyAutoEmailEnabled', value)),
        },
        {
            columnName: 'telegramGroupTitle',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'telegramGroupTitle', value)),
        },
        {
            columnName: 'isTelegramGroupEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isTelegramGroupEnabled', value)),
        },
        {
            columnName: 'whatsAppGroupTitle',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'whatsAppGroupTitle', value)),
        },
        {
            columnName: 'isWhatsAppGroupEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isWhatsAppGroupEnabled', value)),
        },
        {
            columnName: GroupSettingsMappingKeys.isObjectSignInRfidEnabled,
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => mapGroupSettings(row, GroupSettingsMappingKeys.isObjectSignInRfidEnabled, value),
        },
        {
            columnName: 'usernames',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'usernames', value);
            },
        },
        {
            columnName: 'usernamesAdd',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'usernamesAdd', value);
            },
        },
        {
            columnName: 'usernamesRemove',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'usernamesRemove', value);
            },
        },
        {
            columnName: 'sensorControlDeviationTime',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'sensorControlDeviationTime', value)),
        },
        {
            columnName: 'sensorControlAmountTrigger',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'sensorControlAmountTrigger', value)),
        },
        {
            columnName: 'sensorControlIsAmountTriggerEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'sensorControlIsAmountTriggerEnabled', value)),
        },
        {
            columnName: 'sensorItems',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'sensorItems', value);
            }
        },
        {
            columnName: 'sensorControlAdd',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'sensorControlAdd', value);
            }
        },
        {
            columnName: "feedbackControlAmountTriggerVeryGood",
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, "feedbackControlAmountTriggerVeryGood", value)),
        },
        {
            columnName: GroupSettingsMappingKeys.isLightSmilePositiveCountSame,
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, GroupSettingsMappingKeys.isLightSmilePositiveCountSame, value)),
        },
        {
            columnName: "feedbackControlAmountTriggerVeryBad",
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, "feedbackControlAmountTriggerVeryBad", value)),
        },
        {
            columnName: GroupSettingsMappingKeys.isLightSmileNegativCountSame,
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, GroupSettingsMappingKeys.isLightSmileNegativCountSame, value)),
        },
        {
            columnName: GroupSettingsMappingKeys.feedbackDisplayType,
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, GroupSettingsMappingKeys.feedbackDisplayType, value)),
        },
        {
            columnName: GroupSettingsMappingKeys.feedbackCleanerDisplayType,
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, GroupSettingsMappingKeys.feedbackCleanerDisplayType, value)),
        },
        {
            columnName: 'loraSensorControlIsSensorEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'loraSensorControlIsSensorEnabled', value)),
        },
        {
            columnName: 'loraSensorControlDeviationTime',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'loraSensorControlDeviationTime', value)),
        },
        {
            columnName: 'sensorControlIsSensorEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'sensorControlIsSensorEnabled', value)),
        },
        {
            columnName: 'feedbackControlAmountTriggerBad',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'feedbackControlAmountTriggerBad', value)),
        },
        {
            columnName: 'feedbackControlAmountTriggerOk',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'feedbackControlAmountTriggerOk', value)),
        },
        {
            columnName: 'feedbackControlAmountTriggerGood',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'feedbackControlAmountTriggerGood', value)),
        },
        {
            columnName: 'feedbackControlFeedbackDisabledDuration',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'feedbackControlFeedbackDisabledDuration', value)),
        },
        {
            columnName: 'isFeedbackControlEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isFeedbackControlEnabled', value)),
        },
        {
            columnName: 'isSumDisplayEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isSumDisplayEnabled', value)),
        },
        {
            columnName: GroupSettingsMappingKeys.feedbackControlNotificationEnabled,
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => mapGroupSettings(row, GroupSettingsMappingKeys.feedbackControlNotificationEnabled, value),
        },
        {
            columnName: 'feedbackControlDeviationTime',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'feedbackControlDeviationTime', value)),
        },
        {
            columnName: 'feedbackQuestionsItems',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => mapGroupSettings(row, 'feedbackQuestionsItems', value),
        },
        {
            columnName: 'feedbackQuestionsItemsAdd',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => mapGroupSettings(row, 'feedbackQuestionsItemsAdd', value),
        },
        {
            columnName: 'feedbackQuestionsItemsAddMultiple',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => mapGroupSettings(row, 'feedbackQuestionsItemsAddMultiple', value),
        },
        {
            columnName: 'feedbackQuestionsItemsRemove',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => mapGroupSettings(row, 'feedbackQuestionsItemsRemove', value),
        },
        {
            columnName: GroupSettingsMappingKeys.isFeedbackQuestionEnabled,
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => mapGroupSettings(row, GroupSettingsMappingKeys.isFeedbackQuestionEnabled, value),
        },
        {
            columnName: 'feedbackQuestionsPositiveItems',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => mapGroupSettings(row, 'feedbackQuestionsPositiveItems', value),
        },
        {
            columnName: 'feedbackQuestionsPositiveItemsAdd',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => mapGroupSettings(row, 'feedbackQuestionsPositiveItemsAdd', value),
        },
        {
            columnName: 'feedbackQuestionsPositiveItemsAddMultiple',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => mapGroupSettings(row, 'feedbackQuestionsPositiveItemsAddMultiple', value),
        },
        {
            columnName: 'feedbackQuestionsPositiveItemsRemove',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => mapGroupSettings(row, 'feedbackQuestionsPositiveItemsRemove', value),
        },
        {
            columnName: GroupSettingsMappingKeys.isFeedbackQuestionEnabled,
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => mapGroupSettings(row, GroupSettingsMappingKeys.isFeedbackQuestionEnabled, value),
        },
        {
            columnName: GroupSettingsMappingKeys.isFeedbackQuestionPositiveEnabled,
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => mapGroupSettings(row, GroupSettingsMappingKeys.isFeedbackQuestionPositiveEnabled, value),
        },
        {
            columnName: GroupSettingsMappingKeys.isPublicFeedbackEnabled,
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => mapGroupSettings(row, GroupSettingsMappingKeys.isPublicFeedbackEnabled, value),
        },
        {
            columnName: GroupSettingsMappingKeys.isPublicFeedbackRestartEnabled,
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => mapGroupSettings(row, GroupSettingsMappingKeys.isPublicFeedbackRestartEnabled, value),
        },
        {
            columnName: GroupSettingsMappingKeys.isFeedbackCustomTextEnabled,
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => mapGroupSettings(row, GroupSettingsMappingKeys.isFeedbackCustomTextEnabled, value),
        },
        {
            columnName: GroupSettingsMappingKeys.isFeedbackCustomTextPositiveEnabled,
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => mapGroupSettings(row, GroupSettingsMappingKeys.isFeedbackCustomTextPositiveEnabled, value),
        },
        {
            columnName: GroupSettingsMappingKeys.isCleanerFeedbackEnabled,
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => mapGroupSettings(row, GroupSettingsMappingKeys.isCleanerFeedbackEnabled, value),
        },
        {
            columnName: 'groupSettingsIntervalType',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'groupSettingsIntervalType', value)),
        },
        {
            columnName: 'intervalControlStartTime',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'intervalControlStartTime', value)),
        },
        {
            columnName: 'intervalControlEndTime',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'intervalControlEndTime', value)),
        },
        {
            columnName: 'intervalControlRepeatDuration',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'intervalControlRepeatDuration', value)),
        },
        {
            columnName: 'intervalControlDeviationTime',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'intervalControlDeviationTime', value)),
        },
        {
            columnName: 'intervalControlSpecificTimes',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'intervalControlSpecificTimes', value);
            },
        },
        {
            columnName: 'intervalControlSpecificTimesAdd',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'intervalControlSpecificTimesAdd', value);
            },
        },
        {
            columnName: 'intervalControlSpecificTimesRemove',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'intervalControlSpecificTimesRemove', value);
            },
        },
        {
            columnName: 'daysOfWeekEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'daysOfWeekEnabled', value);
            },
        },
        {
            columnName: 'isIntervalControlEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isIntervalControlEnabled', value)),
        },
        {
            columnName: 'isMaterialConsumptionEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isMaterialConsumptionEnabled', value)),
        },
        {
            columnName: 'materialConsumptionsItems',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'materialConsumptionsItems', value);
            },
        },
        {
            columnName: 'materialConsumptionsItemsAdd',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'materialConsumptionsItemsAdd', value);
            },
        },
        {
            columnName: 'materialConsumptionsItemsAddMultiple',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'materialConsumptionsItemsAddMultiple', value);
            },
        },
        {
            columnName: 'materialConsumptionsItemsAddMultipleOld',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'materialConsumptionsItemsAddMultipleOld', value);
            },
        },
        {
            columnName: 'materialConsumptionsItemsRemove',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'materialConsumptionsItemsRemove', value);
            },
        },
        {
            columnName: 'isIncidentReportEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isIncidentReportEnabled', value)),
        },
        {
            columnName: 'isIncidentVisitorCustomTextEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isIncidentVisitorCustomTextEnabled', value)),
        },
        {
            columnName: 'isIncidentVisitorWithNameEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isIncidentVisitorWithNameEnabled', value)),
        },
        {
            columnName: 'isIncidentVisitorEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isIncidentVisitorEnabled', value)),
        },
        {
            columnName: 'incidentReportItems',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'incidentReportItems', value);
            },
        },
        {
            columnName: 'incidentReportItemsAdd',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'incidentReportItemsAdd', value);
            },
        },
        {
            columnName: 'incidentReportItemsAddMultiple',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'incidentReportItemsAddMultiple', value);
            },
        },
        {
            columnName: 'incidentReportItemsAddMultipleOld',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'incidentReportItemsAddMultipleOld', value);
            },
        },
        {
            columnName: 'incidentReportItemsRemove',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'incidentReportItemsRemove', value);
            },
        },

        {
            columnName: 'incidentNameItems',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'incidentNameItems', value);
            },
        },
        {
            columnName: 'incidentNameItemsAdd',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'incidentNameItemsAdd', value);
            },
        },
        {
            columnName: 'incidentNameItemsAddMultiple',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'incidentNameItemsAddMultiple', value);
            },
        },
        {
            columnName: 'incidentNameItemsAddMultipleOld',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'incidentNameItemsAddMultipleOld', value);
            },
        },
        {
            columnName: 'incidentNameItemsRemove',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => {
                return mapGroupSettings(row, 'incidentNameItemsRemove', value);
            },
        },


        {
            columnName: 'contentIsContentEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'contentIsContentEnabled', value)),
        },
        {
            columnName: 'isEasyLoginEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isEasyLoginEnabled', value)),
        },
        {
            columnName: 'displayLastCleaningType',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'displayLastCleaningType', value)),
        },
        {
            columnName: 'contentCode',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'contentCode', value)),
        },
        {
            columnName: 'newsLanguageContent',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'newsLanguageContent', value)),
        },
        {
            columnName: 'weatherCityContent',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'weatherCityContent', value)),
        },
        {
            columnName: 'logo',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'logo', value)),
        },
        {
            columnName: 'sideBarImage',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'sideBarImage', value)),
        },
        {
            columnName: 'name',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'name', value)),
        },
        {
            columnName: 'qrCodeUrl',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'qrCodeUrl', value)),
        },
        {
            columnName: 'inProgressText1',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'inProgressText1', value)),
        },
        {
            columnName: 'inProgressText2',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'inProgressText2', value)),
        },
        {
            columnName: 'inProgressText3',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'inProgressText3', value)),
        },
        {
            columnName: 'inProgressText4',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'inProgressText4', value)),
        },
        {
            columnName: 'inProgressText5',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'inProgressText5', value)),
        },
        {
            columnName: 'byeText1',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'byeText1', value)),
        },
        {
            columnName: 'byeText1Color',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'byeText1Color', value)),
        },
        {
            columnName: 'byeText2',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'byeText2', value)),
        },
        {
            columnName: 'byeText2Color',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'byeText2Color', value)),
        },
        {
            columnName: 'publicText1',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'publicText1', value)),
        },
        {
            columnName: 'publicDisplayText1',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'publicDisplayText1', value)),
        },
        {
            columnName: 'publicDisplayText2',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'publicDisplayText2', value)),
        },
        {
            columnName: 'publicDisplayText1Color',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'publicDisplayText1Color', value)),
        },
        {
            columnName: 'publicDisplayText2Color',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'publicDisplayText2Color', value)),
        },
        {
            columnName: 'displayText1',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'displayText1', value)),
        },
        {
            columnName: 'displayText2',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'displayText2', value)),
        },
        {
            columnName: 'displayText1Color',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'displayText1Color', value)),
        },
        {
            columnName: 'displayText2Color',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'displayText2Color', value)),
        },
        {
            columnName: 'publicText1Color',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'publicText1Color', value)),
        },
        {
            columnName: GroupSettingsMappingKeys.notificationTelegramGroups,
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => mapGroupSettings(row, GroupSettingsMappingKeys.notificationTelegramGroups, value),
        },
        {
            columnName: "feedbackControlAmountTriggerVeryBad",
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, "feedbackControlAmountTriggerVeryBad", value)),
        },
        {
            columnName: 'contentCombinationModify',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'contentCombinationModify', value)),
        },
        {
            columnName: "contentCombinationAdvertisementModify",
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => mapGroupSettings(row, "contentCombinationAdvertisementModify", value),
        },
        {
            columnName: 'row',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'row', value)),
        },
        {
            columnName: 'addContentCombination',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'addContentCombination', value)),
        },
        {
            columnName: 'isRfidExtraLoginEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isRfidExtraLoginEnabled', value)),
        },
        {
            columnName: 'isServiceListOnWaitingScreenEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isServiceListOnWaitingScreenEnabled', value)),
        },
        {
            columnName: 'isFeedbackQuestionListOnWaitingScreenEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isFeedbackQuestionListOnWaitingScreenEnabled', value)),
        },
        {
            columnName: 'isIncidentVisitorListOnWaitingScreenEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isIncidentVisitorListOnWaitingScreenEnabled', value)),
        },
        {
            columnName: 'isFeedbackQuestionListOnServiceModeEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isFeedbackQuestionListOnServiceModeEnabled', value)),
        },
        {
            columnName: 'isIncidentVisitorListOnServiceModeEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isIncidentVisitorListOnServiceModeEnabled', value)),
        },
        {
            columnName: 'isLastCheckEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isLastCheckEnabled', value)),
        },
        {
            columnName: 'isObjectPresenceVisibleEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isObjectPresenceVisibleEnabled', value)),
        },
        {
            columnName: 'isAutoApkInstallEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isAutoApkInstallEnabled', value)),
        },
        {
            columnName: 'isLastCleaningEnabled',
            createRowChange: (row: UserGroupSettingsTemplate, value: string) => (mapGroupSettings(row, 'isLastCleaningEnabled', value)),
        },
    ]);

    const commitChanges = async ({ added, changed, deleted }: any) => {
        let rows: UserGroupSettingsTemplate[] = Object.assign([], userGroupSettings);

        if (changed) {

            for (let userGroupSetting of userGroupSettings) {

                let changedRow = changed[userGroupSetting.id || ""] as UserGroupSettingsTemplate;

                if (!changedRow) continue;
                changedRow.systemETag = null;
                const updatedRow = await api.updateUserGroupSettingsTemplate(changedRow, token, enqueueSnackbar);
                if (updatedRow) {
                    changedRow.systemETag = updatedRow.systemETag;
                    changedRow.systemTimestamp = updatedRow.systemTimestamp;
                }

                if (changedRow.locationInformation)
                    changedRow.locationInformation.password = '';
            }

            rows = userGroupSettings.map(row => {
                return (changed[row.id || ""] ? { ...row, ...changed[row.id || ""] } : row)
            });
        }

        if (added) {
            for (const addedItem of added) {

                if (!addedItem) continue;

                const addedRow = await api.createUserGroupSettingsTemplate(addedItem as UserGroupSettingsTemplate, token, enqueueSnackbar);

                if (!addedRow?.id) continue;

                rows.push(addedRow);
            }
        }
        if (deleted) {
            setDeletedRowId(deleted[0]);
            setConfirmationDialogVisible(true);
        } else {
            setUserGroupSettings(rows);
            loadData(token);
        }


    };

    const exporterRef = useRef<typeof GridExporter>(null);

    const startExport = useCallback(() => {
        if (exporterRef != null && exporterRef.current != null) {
            exporterRef.current.exportGrid();
        }
    }, [exporterRef]);

    const [openSettingsLoad, setOpenSettingsLoad] = React.useState(false);
    const [currentSelectedGroupSettingsLoadRow, setCurrentSelectedGroupSettingsLoadRow] = React.useState();

    const AddButton = ({ onExecute }: any) => (
        <div style={{ textAlign: 'center' }}>
            <Button
                color="primary"
                variant='contained'
                onClick={onExecute}
                title="Create new row"
                endIcon={<Add />}
            >
                Neue Vorlage
            </Button>
        </div>
    );

    const EditButton = ({ onExecute }: any) => (
        <IconButton color='primary' onClick={onExecute} title="Edit row" size="large">
            <Edit />
        </IconButton>
    );

    const DeleteButton = ({ onExecute }: any) => (
        <IconButton
            onClick={() => {
                // eslint-disable-next-line
                onExecute();
            }}
            title="Delete row"
            size="large"
            color='error'
        >
            <Delete />
        </IconButton>
    );

    const commandComponents: any = {
        add: AddButton,
        edit: EditButton,
        delete: DeleteButton
    };

    const Command = ({ id, onExecute }: any) => {
        const CommandButton = commandComponents[id];
        return (
            <CommandButton
                onExecute={onExecute}
            />
        );
    };

    return (
        <Paper >
            <Grid
                rows={userGroupSettings}
                columns={columns}
                getRowId={(row) => row.id}
            >
                <SearchState />
                <FilteringState defaultFilters={[]} />
                <IntegratedFiltering />
                <SortingState />
                <EditingState
                    columnExtensions={editingColumnExtensions}
                    onCommitChanges={commitChanges}
                />
                <DragDropProvider />

                <IntegratedSorting />
                <Table cellComponent={(props: any) => Cell({ ...props, setOpenSettingsLoad: setOpenSettingsLoad, setCurrentSelectedGroupSettingsLoadRow: setCurrentSelectedGroupSettingsLoadRow })} />
                <TableColumnReordering
                    defaultOrder={[
                        'loadGroupSettings',
                        'name',
                        'objectName',
                        'levelName',
                        'roomName',
                        'toiletType',
                        GroupSettingsMappingKeys.notificationTelegramPhoneNumber,
                        'locationPassword',
                        'notificationName',
                        'cleaningOperations',
                        'cleaningEmail',
                        'incidentReportEmail',
                        'telegramGroupTitle',
                        'whatsAppGroupName',
                        'usernames',
                        'sensorControlIsSensorEnabled',
                        'sensorControlAmountTrigger',
                        'sensorControlIsAmountTriggerEnabled',
                        'sensorItems',
                        'feedbackControlAmountTriggerBad',
                        'feedbackControlAmountTriggerOk',
                        'feedbackControlAmountTriggerGood',
                        'isFeedbackControlEnabled',
                        'isSumDisplayEnabled',
                        'intervalControlIsIntervalEnabled',
                        'intervalControlStartTime',
                        'intervalControlEndTime',
                        'intervalControlRepeatDuration',
                        'intervalControlDeviationTime',
                        'intervalControlIsSpecificTimeEnabled',
                        'intervalControlSpecificTimes',
                        'isIntervalControlEnabled',
                        'isMaterialConsumptionEnabled',
                        'materialConsumptionsItems',
                        'isIncidentReportEnabled',
                        'incidentReportItems',
                        'id'
                    ]}
                />
                <TableColumnVisibility
                    defaultHiddenColumnNames={[]}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow showSortingControls />
                <TableFilterRow cellComponent={FilterCell} />
                <TableEditColumn
                    width={180}
                    showAddCommand
                    showEditCommand
                    showDeleteCommand
                    commandComponent={Command}
                />
                <PopupEditingGroupSettingsTemplate popupComponent={PopupGroupSettingsTemplate} />
                <Toolbar />
                <SearchPanel />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={userGroupSettings}
                columns={columns}
                onSave={onSave}
            />
            <GroupSelectionTemplateSettingsLoadDialog groups={groups} open={openSettingsLoad} setOpen={setOpenSettingsLoad} currentSelectedGroupSettingsLoadRow={currentSelectedGroupSettingsLoadRow} token={token} />
            <ConfirmationDialog
                title="Wollen Sie diese Vorlage wirklich löschen?"
                visible={confirmationDialogVisible}
                onClose={() => setConfirmationDialogVisible(false)}
                onConfirmed={async () => {
                    let rows: UserGroupSettingsTemplate[] = Object.assign([], userGroupSettings);
                    await api.deleteUserGroupSettingsTemplate(deletedRowId, token, enqueueSnackbar);
                    rows = rows.filter(row => deletedRowId != row.id);
                    setUserGroupSettings(rows);
                    setConfirmationDialogVisible(false);
                    loadData(token);
                }}
            />
        </Paper>
    );
};