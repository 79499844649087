import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, VirtualTable, } from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import { GroupLicenseTable, SensorLicense } from '../../models/GroupLicenseTable';
import { useHistory } from 'react-router-dom';
import { toiletTypePredicate } from './components/Cells';
import { formatEuropeanDate, formatEuropeanDateTime } from '../../helpers/date-management';
import { mapToiletTypeKey } from './Base';
import React, { useCallback, useRef, useState } from 'react';
import { Autocomplete, List, ListItem, ListItemIcon, ListItemText, TextField, Typography } from '@mui/material';
import { ReactComponent as MotionSensorSvg } from '../../assets/svgs/motion-sensor.svg';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { ObjectUnit } from '../../models/ObjectUnit';
import { buildCellObject } from '../authorized/extendedapp/serviceWorker/UserServiceWorkerManagementPageDataTable';
import { Check, CheckCircle, Error, Sensors } from '@mui/icons-material';
import { green, red } from '../../styles/colors';
import { flexRowCenter, useSizeStyles } from '../../styles/common';
import { UserServiceWorker } from '../../models/UserServiceWorker';
import { buildCellStringArray } from '../common/AccordionForStringArray';
import { Group } from '../../models/Group';
import { saveExcelFile, virtualTableHeight } from '../../helpers/tables/common';
import { UserProperty } from '../../models/UserProperty';
import { GlobalContext } from '../authorized/PortalPage';
import { StandardWorkItem } from '../../models/StandardItem';
import { useTranslation } from 'react-i18next';

const onSave = (workbook: any) => {
    workbook.xlsx.writeBuffer().then((buffer: any) => {
        saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), 'Einheiten');
    });
};

export const buildCellAssignedColumn = (assigned: boolean, props: any) => {
    const { column, row, value } = props;
    return (
        assigned ?
            <Table.Cell {...props} style={{ color: green }}>
                <div className="Flex-Row-Center">
                    <Typography className="Big-Cell-Font" style={{ marginRight: 10 }}>{value} </Typography>
                    <CheckCircle className="Big-Cell-Font" />
                </div>
            </Table.Cell > :
            <Table.Cell {...props} style={{ color: red }}>
                <div className="Flex-Row-Center">
                    <Typography className="Big-Cell-Font" style={{ marginRight: 10 }}>{value} </Typography>
                    <Error className="Big-Cell-Font" />
                </div>
            </Table.Cell >

    );
}


export const GroupSelectionTable = ({ groups, selectedGroups, onSelectionChange,
    selectedObjectId, selectedServiceWorkerId, objects, userServiceWorkers,
    properties, selectedPropertyId, setGroups, showIsQmAssigned = false, standardRoomTypes = [] }
    : {
        groups: GroupLicenseTable[], selectedGroups: GroupLicenseTable[], onSelectionChange: any,
        selectedObjectId?: string | undefined, selectedServiceWorkerId?: string | undefined, objects?: ObjectUnit[] | undefined, userServiceWorkers?: UserServiceWorker[] | undefined,
        properties?: UserProperty[] | undefined, selectedPropertyId?: string | undefined, setGroups?: any, showIsQmAssigned?: boolean, standardRoomTypes?: StandardWorkItem[]
    }) => {

    const exporterRef: any = useRef(null);

    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);
    const {t} = useTranslation();
    const [pageSize, setPageSize] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);
    const { filtersGroupLicenseTable, setFiltersGroupLicenseTable }: { filtersGroupLicenseTable: any[], setFiltersGroupLicenseTable: any } = React.useContext(GlobalContext);

    const Cell = (props: any) => {
        const { column, row }: { column: any, row: GroupLicenseTable } = props;
        const [roomSize, setRoomSize] = React.useState(row.roomSize);
        const [roomType, setRoomType] = React.useState(row.roomType);


        if (column.name === GroupLicenseTable.Columns.sensorLicenseValidUntil && row.sensorLicenses) {
            return buildCellStringArray(props, row.sensorLicenses.map(x => formatEuropeanDate(x.sensorLicenseValidUntilDate)), `Enthält ${row.sensorLicenses.length} Sensor Lizenzen`, null,
                row.sensorLicenses.map(x => <MotionSensorSvg fill={x.isSensorLicenseExpired ? "#eb4034" : "#2ee656"} />));
        }
        if (column.name === GroupLicenseTable.Columns.toiletType && showIsQmAssigned) {
            return (
                <Table.Cell {...props}>
                    <Autocomplete
                        freeSolo
                        value={roomType}
                        onChange={(event: any, newValue: string | null) => {
                            if (!newValue) return;

                            setRoomType(newValue);
                        }}

                        id="controllable-states-demo"
                        options={standardRoomTypes.map(x => x.text)}
                        sx={{ width: 300 }}
                        renderInput={(params: any) =>
                        (
                            <TextField
                                variant='outlined'
                                onChange={(event) => {
                                    setRoomType(event.target.value)
                                }}
                                onBlur={(event: any) => {
                                    if (setGroups) {
                                        row.edited = true;
                                        row.roomType = event.target.value;
                                        setGroups([...groups]);
                                    }


                                }
                                } {...params} />
                        )
                        }
                    />
                </Table.Cell>
            )
        }
        if (column.name === GroupLicenseTable.Columns.roomSize && showIsQmAssigned) {
            return (
                <Table.Cell {...props}>
                    <TextField
                        fullWidth
                        id="filled-name"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        type='number'
                        value={roomSize}
                        onChange={(event: any) => {
                            if (event.target.value) {
                                setRoomSize(event.target.value)
                            } else {
                                setRoomSize(0)
                            }
                        }}
                        onBlur={(event: any) => {
                            if (setGroups && event.target.value) {
                                row.edited = true;
                                row.roomSize = parseFloat(event.target.value);
                                setGroups([...groups]);
                            }


                        }}
                    />
                </Table.Cell>
            )
        }
        if (column.name === GroupLicenseTable.Columns.loraSensorLicenseValidUntil && row.loraSensorLicenses) {
            return buildCellStringArray(props, row.loraSensorLicenses.map(x => formatEuropeanDate(x.sensorLicenseValidUntilDate)), `Enthält ${row.loraSensorLicenses.length} Lora Sensor Lizenzen`, null,
                row.loraSensorLicenses.map(x => <Sensors style={{ color: x.isSensorLicenseExpired ? "#eb4034" : "#2ee656" }} />));
        }
        if (column.name == GroupLicenseTable.Columns.currentAssignedObject) {
            return buildCellObject(objects?.find(x => x.id == row.objectUnitId), props);
        }
        if (column.name == GroupLicenseTable.Columns.assignedObject) {
            return buildCellAssignedColumn(row.objectUnitId == selectedObjectId, props);
        }
        if (column.name == GroupLicenseTable.Columns.assignedQM) {
            return buildCellAssignedColumn(row.isQmControlEnabled, props);
        }
        if (column.name == GroupLicenseTable.Columns.assignedServiceWorker) {
            return buildCellAssignedColumn(row.userServiceWorkerIds.includes(selectedServiceWorkerId || ""), props);
        }
        if (column.name == GroupLicenseTable.Columns.assignedProperty) {
            return buildCellAssignedColumn(row.userPropertyIds.includes(selectedPropertyId || ""), props);
        }
        if (column.name == GroupLicenseTable.Columns.currentAssignedServiceWorkers && userServiceWorkers) {
            let userServiceWorkersForRow = userServiceWorkers.filter(x => row.userServiceWorkerIds.includes(x.id)).map(x => `${x.name} ${x.phoneNumber}`);
            return buildCellStringArray(props, userServiceWorkersForRow, `Enthält ${userServiceWorkersForRow.length} Servicekräfte`);
        }
        if (column.name == GroupLicenseTable.Columns.currentAssignedProperties && properties) {
            let propertiesForRow = properties.filter(x => row.userPropertyIds.includes(x.id)).map(x => `${x.name}`);
            return buildCellStringArray(props, propertiesForRow, `Enthält ${propertiesForRow.length} Merkmale`);
        }
        if (column.name === GroupLicenseTable.Columns.loraSensors) {
            return buildCellStringArray(props, row.loraSensors.map(x => x.id), `Enthält ${row.loraSensors.length} Lora-Sensor`, null,
                row.loraSensors.map(x => <MotionSensorSvg />));;
        }
        if (column.name === GroupLicenseTable.Columns.deviceSensorId && row.sensors) {
            return buildCellStringArray(props, row.sensors.map(x => x.id), `Enthält ${row.sensors.length} Sensoren`, null,
                row.sensors.map(x => <MotionSensorSvg />));
        }
        return <Table.Cell {...props} />;
    };


    let columns = [
        {
            name: GroupLicenseTable.Columns.groupType,
            title: t("license-general.unitType"),
            getCellValue: (row: GroupLicenseTable) => Group.GetLabelByType(row.groupType),
        },
        {
            name: GroupLicenseTable.Columns.notificationName,
            title: t("license-general.unitName"),
            getCellValue: (row: GroupLicenseTable) => row.notificationName,
        },
        {
            name: GroupLicenseTable.Columns.objectName,
            title: t("license-general.objectName"),
            getCellValue: (row: GroupLicenseTable) => row.objectName,
        },
        {
            name: GroupLicenseTable.Columns.objectCostLocation,
            title: t("license-general.objectCostCenter"),
            getCellValue: (row: GroupLicenseTable) => row.objectCostLocation,
        },
        {
            name: GroupLicenseTable.Columns.objectDescription,
            title: t("license-general.objectDescription"),
            getCellValue: (row: GroupLicenseTable) => row.objectDescription,
        },
        {
            name: GroupLicenseTable.Columns.levelName,
            title: t("license-general.level"),
            getCellValue: (row: GroupLicenseTable) => row.levelName,
        },
        {
            name: GroupLicenseTable.Columns.roomName,
            title: t("license-general.customer"),
            getCellValue: (row: GroupLicenseTable) => row.roomName,
        },
        {
            name: GroupLicenseTable.Columns.toiletType,
            title: t("license-general.roomType"),
            getCellValue: (row: GroupLicenseTable) => GroupLicenseTable.getCellRoomType(row),
        },
        {
            name: GroupLicenseTable.Columns.roomSize,
            title: t("myUnitsSettings.roomSize"),
            getCellValue: (row: GroupLicenseTable) => row.roomSize,
        },
        {
            name: GroupLicenseTable.Columns.uniqueDeviceId,
            title: 'Device Id',
            getCellValue: (row: GroupLicenseTable) => row.uniqueDeviceId,
        },
        {
            name: GroupLicenseTable.Columns.userPropertyName,
            title: t("myUnitsSettings.featureName"),
            getCellValue: (row: GroupLicenseTable) => row.userPropertyName,
        },
        {
            name: GroupLicenseTable.Columns.deviceLicenseValidUntil,
            title: t("license-general.displayValidUntil"),
            getCellValue: (row: GroupLicenseTable) => row.deviceLicenseIsValid ? (row.deviceLicenseIsExpired ? 'abgelaufen' : formatEuropeanDate(row.deviceLicenseValidUntil)) : '',
        },
        {
            name: GroupLicenseTable.Columns.adLicenseValidUntil,
            title: t("license-general.advertisingLicenseValidUntil"),
            getCellValue: (row: GroupLicenseTable) => row.adLicenseIsValid ? (row.adLicenseIsExpired ? 'abgelaufen' : formatEuropeanDate(row.adLicenseValidUntil)) : '',
        },
        {
            name: GroupLicenseTable.Columns.qualityCheckLicenseValidUntil,
            title: t("myUnitsSettings.qualityControlLicenseValidUntil"),
            getCellValue: (row: GroupLicenseTable) => row.qualityCheckLicenseIsValid ? (row.qualityCheckLicenseIsExpired ? 'abgelaufen' : formatEuropeanDate(row.qualityCheckLicenseValidUntil)) : '',
        },
        {
            name: GroupLicenseTable.Columns.loraSensorLicenseValidUntil,
            title: t("myUnitsSettings.loraSensorLicensesValidUntil"),
            getCellValue: (row: GroupLicenseTable) => row.loraSensorLicenses ? row.loraSensorLicenses.map(x => formatEuropeanDate(x.sensorLicenseValidUntilDate)).join(',') : "Keine"
        },
        {
            name: GroupLicenseTable.Columns.loraSensors,
            title: t("myUnitsSettings.assignedLoraSensors"),
            getCellValue: (row: GroupLicenseTable) => row.loraSensorLicenses ? row.loraSensors.map(x => x.id).join(',') : "Keine"
        },
        {
            name: GroupLicenseTable.Columns.sensorLicenseValidUntil,
            title: t("myUnitsSettings.sensorLicensesValidUntil"),
            getCellValue: (row: GroupLicenseTable) => row.sensorLicenses ? row.sensorLicenses.map(x => formatEuropeanDate(x.sensorLicenseValidUntilDate)).join(',') : []
        },
        {
            name: GroupLicenseTable.Columns.deviceSensorId,
            title: 'Sensor Id',
            getCellValue: (row: GroupLicenseTable) => row?.sensors?.map(x => x.id).join(","),
        },
        {
            name: GroupLicenseTable.Columns.groupCreatedDate,
            title: t("myUnitsSettings.unitActivatedOn"),
            getCellValue: (row: GroupLicenseTable) => formatEuropeanDateTime(new Date(row.groupCreatedDate))
        }
    ];

    let columnWidth = Object.keys(GroupLicenseTable.Columns).map(el => {
        if (el == GroupLicenseTable.Columns.currentAssignedObject || el == GroupLicenseTable.Columns.currentAssignedProperties) {
            return { columnName: el, width: 170 }
        }
        if (el == GroupLicenseTable.Columns.currentAssignedServiceWorkers) {
            return { columnName: el, width: 300 }
        }
        if (el == GroupLicenseTable.Columns.sensorLicenseValidUntil) {
            return { columnName: el, width: 300 }
        }
        return { columnName: el, width: 170 }
    });

    if (selectedObjectId) {
        const objectAssignedColumn = {
            name: GroupLicenseTable.Columns.assignedObject,
            title: 'Zugewiesen',
            getCellValue: (row: GroupLicenseTable) => row.objectUnitId == selectedObjectId ? "Ja" : "Nein"
        }
        const currentObjectAssignedColumn = {
            name: GroupLicenseTable.Columns.currentAssignedObject,
            title: 'Aktuelles Objekt zugewiesen',
            getCellValue: (row: GroupLicenseTable) => {
                let object = objects?.find(x => x.id == row.objectUnitId);
                return (
                    !object ? "Keines Zugewiesen" : `${object?.name} ${object?.customerName} ${object?.addressLine} ${object?.city}`
                );
            }
        }
        columns = [objectAssignedColumn, currentObjectAssignedColumn, ...columns];

    }

    if (showIsQmAssigned) {
        const qmAssignedColumn = {
            name: GroupLicenseTable.Columns.assignedQM,
            title: 'Zugewiesen',
            getCellValue: (row: GroupLicenseTable) => row.isQmControlEnabled ? "Ja" : "Nein"
        }
        columns = [qmAssignedColumn, ...columns];
    }

    if (selectedServiceWorkerId) {
        const serviceWorkerAssignedColumn = {
            name: GroupLicenseTable.Columns.assignedServiceWorker,
            title: 'Zugewiesen',
            getCellValue: (row: GroupLicenseTable) => row.userServiceWorkerIds.includes(selectedServiceWorkerId) ? "Ja" : "Nein"
        }
        const currentServiceWorkersAssignedColumn = {
            name: GroupLicenseTable.Columns.currentAssignedServiceWorkers,
            title: 'Aktuelle Servicekräfte zugewiesen',
            getCellValue: (row: GroupLicenseTable) => {
                let userServiceWorkersForRow = userServiceWorkers?.filter(x => row.userServiceWorkerIds.includes(x.id));
                return (
                    !userServiceWorkersForRow ? "Keine Zugewiesen" : `${userServiceWorkersForRow.map(x => `${x.name} ${x.phoneNumber}`).join(',')}`
                );
            }
        }
        columns = [serviceWorkerAssignedColumn, currentServiceWorkersAssignedColumn, ...columns];
    }

    if (selectedPropertyId) {
        const propertyAssignedColumn = {
            name: GroupLicenseTable.Columns.assignedProperty,
            title: 'Zugewiesen',
            getCellValue: (row: GroupLicenseTable) => row.userPropertyIds.includes(selectedPropertyId) ? "Ja" : "Nein"
        }
        const propertiesAssignedColumn = {
            name: GroupLicenseTable.Columns.currentAssignedProperties,
            title: 'Aktuelle Merkmale zugewiesen',
            getCellValue: (row: GroupLicenseTable) => {
                let propertiesForRow = properties?.filter(x => row.userPropertyIds.includes(x.id));
                return (
                    !propertiesForRow ? "Keine Zugewiesen" : `${propertiesForRow.map(x => `${x.name} ${formatEuropeanDate(x.creationDate)}`).join(',')}`
                );
            }
        }
        columns = [propertyAssignedColumn, propertiesAssignedColumn, ...columns];
    }

    return (
        <>
            <Grid
                getRowId={(row) => row.id}
                rows={groups}
                columns={columns}
            >
                <FilteringState
                    filters={filtersGroupLicenseTable}
                    onFiltersChange={setFiltersGroupLicenseTable as any}
                />
                <IntegratedFiltering columnExtensions={[
                    { columnName: GroupLicenseTable.Columns.toiletType, predicate: toiletTypePredicate },
                ]} />

                <SortingState
                    defaultSorting={[{ columnName: GroupLicenseTable.Columns.notificationName, direction: 'desc' }]}
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState
                    selection={selectedGroups.filter(x => !!x && x.id != undefined).map(x => x.id)}
                    onSelectionChange={onSelectionChange}
                />
                <IntegratedPaging />
                <IntegratedSelection />
                <VirtualTable
                    height={virtualTableHeight}
                    cellComponent={Cell}
                />
                <TableColumnResizing defaultColumnWidths={columnWidth} />
                <TableColumnVisibility />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableSelection showSelectAll />
                <TableHeaderRow />
                <TableFilterRow />
                <PagingPanel
                    pageSizes={[0, 5, 10, 15]}
                />
            </Grid>
            <GridExporter
                ref={exporterRef}
                rows={groups}
                columns={columns}
                onSave={onSave}
            />
        </>
    );
};