import Paper from '@mui/material/Paper';
import { ColumnChooser, ExportPanel, Grid, PagingPanel, Table, TableColumnResizing, TableColumnVisibility, TableFilterRow, TableHeaderRow, TableSelection, Toolbar, } from '@devexpress/dx-react-grid-material-ui';
import { FilteringState, IntegratedFiltering, IntegratedPaging, IntegratedSelection, IntegratedSorting, PagingState, SelectionState, SortingState } from '@devexpress/dx-react-grid';

import React, { useCallback, useRef } from 'react';
import { UserServiceWorkerObjectSession } from '../../../models/UserServiceWorkerObjectSession';
import { CircularProgress, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { lightgrey } from '../../../styles/colors';
import { ObjectUnit } from '../../../models/ObjectUnit';
import { buildCellObject } from '../../authorized/extendedapp/serviceWorker/UserServiceWorkerManagementPageDataTable';
import { convertMinuteToHours, formatEuropeanCurrentDateForExcelExport, formatEuropeanDate, formatEuropeanDateTime, formatEuropeanTime } from '../../../helpers/date-management';
import { UserServiceWorker } from '../../../models/UserServiceWorker';
import { Accessibility, DeleteForever } from '@mui/icons-material';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { saveExcelFile } from '../../../helpers/tables/common';
import { differenceInHours, differenceInMinutes, formatDistance, isAfter, isBefore, subDays } from 'date-fns';
import { de } from 'date-fns/locale';
import { Button, Grid as MuiGrid, List, ListItemIcon, ListItemText, TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useSnackbar } from 'notistack';
import { getApiToken } from '../../../helpers/storage-management';
import { api } from '../../../api';
import { useHistory } from 'react-router-dom';
import { GroupLicenseTable } from '../../../models/GroupLicenseTable';
import { useTranslation } from 'react-i18next';


export const ObjectSessionsByObjectIdTable = ({ userServiceWorkerObjectSessions, userServiceWorkers, selectedObjectUnit, objects = [], setUserServiceWorkerObjectSessions, groups, startDate, endDate, setStartDate, setEndDate }
    : { userServiceWorkerObjectSessions: UserServiceWorkerObjectSession[], userServiceWorkers: UserServiceWorker[], selectedObjectUnit?: ObjectUnit, objects?: ObjectUnit[], setUserServiceWorkerObjectSessions: any, groups: GroupLicenseTable[], startDate? : Date | undefined, endDate? : Date | undefined, setStartDate? : any, setEndDate?: any }) => {
    const [selectedRows, setSelectedRows] = React.useState<UserServiceWorkerObjectSession[]>([]);
    const history = useHistory();

    const [isLoadingDeleting, setIsLoadingDeleting] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const {t} = useTranslation();


    const onSave = (workbook: any) => {
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            saveExcelFile(new Blob([buffer], { type: 'application/octet-stream' }), `ObjectSession-${selectedObjectUnit?.name || "All-Objects"}-${formatEuropeanCurrentDateForExcelExport()}`);
        });
    };

    const customizeCell = (cell: any, row: any, column: any) => {
        if (column.name === UserServiceWorkerObjectSession.Columns.durationHours) {
            cell.value = +cell.value;
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFBB00' } };

        }
    };
    const [filters, setFilters]: any = React.useState([]);
    const [startDateLocal, setStartDateLocal] = React.useState(subDays(new Date(), 100));
    const [endDateLocal, setEndDateLocal] = React.useState(new Date());

    const classes = useStyles();

    const [pageSize, setPageSize] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(0);

    const Cell = (props: any) => {
        const { column, row, value } = props;
        if (column.name === UserServiceWorkerObjectSession.Columns.serviceWorker) {
            let object = userServiceWorkers.find(x => x.id == row.userServiceWorkerId);
            return <Table.Cell {...props}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', textAlign: 'center' }}>
                    <Accessibility />
                    <Typography>{value}</Typography>
                </div>
            </Table.Cell>;
        }
        return <Table.Cell {...props} />;
    };

    const exporterRef: any = useRef(null);
    const startExport = useCallback(() => {
        exporterRef.current.exportGrid();
    }, [exporterRef]);

    let columns = [
        {
            name: UserServiceWorkerObjectSession.Columns.serviceWorker,
            title: t("object-sessions.serviceStaff"),
            getCellValue: (row: UserServiceWorkerObjectSession) => {
                let userServiceWorker = userServiceWorkers.find(x => x.id == row.userServiceWorkerId);
                return (
                    !userServiceWorker ? `anonym ${row.nfcCode}` : `${userServiceWorker?.name} ${userServiceWorker?.phoneNumber}`
                );
            }
        },
        {
            name: UserServiceWorkerObjectSession.Columns.signedInObjectSinceTimestamp,
            title: t("object-sessions.registrationDate"),
            getCellValue: (row: UserServiceWorkerObjectSession) => formatEuropeanDate(row.signedInObjectSinceTimestamp),
        },
        {
            name: UserServiceWorkerObjectSession.Columns.signedInObjectSinceTime,
            title: t("object-sessions.registrationTime"),
            getCellValue: (row: UserServiceWorkerObjectSession) => formatEuropeanTime(row.signedInObjectSinceTimestamp),
        },
        {
            name: UserServiceWorkerObjectSession.Columns.signedOutOfObjectSinceTimestamp,
            title: t("object-sessions.LogoutDate"),
            getCellValue: (row: UserServiceWorkerObjectSession) => formatEuropeanDate(row.signedOutOfObjectSinceTimestamp),
        },
        {
            name: UserServiceWorkerObjectSession.Columns.signedOutOfObjectSinceTime,
            title: t("object-sessions.LogoutTime"),
            getCellValue: (row: UserServiceWorkerObjectSession) => formatEuropeanTime(row.signedOutOfObjectSinceTimestamp),
        },
        {
            name: UserServiceWorkerObjectSession.Columns.duration,
            title: t("object-sessions.attendance"),
            getCellValue: (row: UserServiceWorkerObjectSession) => {
                return formatDistance(new Date(row.signedInObjectSinceTimestamp), new Date(row.signedOutOfObjectSinceTimestamp), { locale: de })
            }
        },
        {
            name: UserServiceWorkerObjectSession.Columns.durationMinutes,
            title: t("object-sessions.attendanceDurationMinutes"),
            getCellValue: (row: UserServiceWorkerObjectSession) => {
                return differenceInMinutes(new Date(row.signedOutOfObjectSinceTimestamp), new Date(row.signedInObjectSinceTimestamp))
            }
        },
        {
            name: UserServiceWorkerObjectSession.Columns.durationHours,
            title: t("object-sessions.attendanceDurationHours"),
            getCellValue: (row: UserServiceWorkerObjectSession) => {

                /*  let hours = differenceInHours(new Date(row.signedOutOfObjectSinceTimestamp), new Date(row.signedInObjectSinceTimestamp));
                  let minutes = differenceInMinutes(new Date(row.signedOutOfObjectSinceTimestamp), new Date(row.signedInObjectSinceTimestamp));
  
                  return `${hours}:${minutes - hours * 60}`; */


                let res = convertMinuteToHours(differenceInMinutes(new Date(row.signedOutOfObjectSinceTimestamp), new Date(row.signedInObjectSinceTimestamp)));
                let hours = res.split('.')[0];
                let minutes = res.split('.')[1];
                //      return `${hours},${minutes}`
                return res;
            }
        },
        {
            name: UserServiceWorkerObjectSession.Columns.groupId,
            title: t("object-sessions.loginUnit"),
            getCellValue: (row: UserServiceWorkerObjectSession) => {
                let group = groups.find(x=>x.id == row.groupId);
                return group?.notificationName || t("object-sessions.noUnit")
            },
        },
        {
            name: UserServiceWorkerObjectSession.Columns.logoutGroupId,
            title: t("object-sessions.logoutUnit"),
            getCellValue: (row: UserServiceWorkerObjectSession) => {
                let group = groups.find(x=>x.id == row.logoutGroupId);
                return group?.notificationName || t("object-sessions.noUnit")
            },
        },
    ];

    const [columnWidths, setColumnWidths] = React.useState([
        { columnName: UserServiceWorkerObjectSession.Columns.object, width: 350 },
        { columnName: UserServiceWorkerObjectSession.Columns.serviceWorker, width: 350 },
        { columnName: UserServiceWorkerObjectSession.Columns.signedInObjectSinceTimestamp, width: 200 },
        { columnName: UserServiceWorkerObjectSession.Columns.signedOutOfObjectSinceTimestamp, width: 200 },
        { columnName: UserServiceWorkerObjectSession.Columns.signedOutOfObjectSinceTime, width: 200 },
        { columnName: UserServiceWorkerObjectSession.Columns.signedInObjectSinceTime, width: 200 },
        { columnName: UserServiceWorkerObjectSession.Columns.duration, width: 200 },
        { columnName: UserServiceWorkerObjectSession.Columns.durationMinutes, width: 200 },
        { columnName: UserServiceWorkerObjectSession.Columns.durationHours, width: 200 },
        { columnName: UserServiceWorkerObjectSession.Columns.object, width: 200 },
        { columnName: UserServiceWorkerObjectSession.Columns.groupId, width: 200 },
        { columnName: UserServiceWorkerObjectSession.Columns.logoutGroupId, width: 200 },

    ]);

    if (objects.length > 0) {
        columns = [{
            name: UserServiceWorkerObjectSession.Columns.object,
            title: 'Object',
            getCellValue: (row: UserServiceWorkerObjectSession) => {
                let object = objects.find(x => x.id == row.objectId);
                return (
                    !object ? t("object-sessions.notActive") : `${object?.name} ${object?.customerName} ${object?.addressLine} ${object?.city}`
                );
            }
        }, ...columns]
    }

    const rows = userServiceWorkerObjectSessions.filter(x => isAfter(new Date(x.creationDate), startDate || startDateLocal) && isBefore(new Date(x.creationDate), endDate || endDateLocal))


    return (
        <Paper>
            <MuiGrid xs={12} item container spacing={3} style={{marginTop: 20}}>
                <MuiGrid xs={12} item container direction={"row"} alignItems="center" spacing={2}>
                    <MuiGrid item>
                        <Button
                            size="large"
                            fullWidth
                            color="error"

                            startIcon={isLoadingDeleting && <CircularProgress size={32} />}
                            disabled={selectedRows.length < 1 || isLoadingDeleting} variant='contained'
                            endIcon={<DeleteForever />} onClick={async () => {
                                setIsLoadingDeleting(true);
                                let token = getApiToken(history);
                                for (let serviceMode of selectedRows) {
                                    await api.deleteServiceWorkerObjectSession(serviceMode.id, token, enqueueSnackbar);
                                    setUserServiceWorkerObjectSessions((prev: UserServiceWorkerObjectSession[]) => prev.filter(x => x.id != serviceMode.id));
                                    setSelectedRows((prev) => prev.filter(x => x.id != serviceMode.id));
                                    
                                }
                                setIsLoadingDeleting(false);
                            }}>
                            {selectedRows.length} {t("object-sessions.deleteObjectRegistration")}
                        </Button>
                    </MuiGrid>
                </MuiGrid>
                <MuiGrid item>
                    < DateTimePicker
                        disableFuture
                        slotProps={{ textField: { variant: 'outlined' } }}
                        label={t("object-sessions.startDate")}
                        value={startDate || startDateLocal}
                        onChange={(newVal) => {
                            if (!newVal) return;
                            if (setStartDate) setStartDateLocal(newVal);
                            setStartDateLocal(newVal);
                        }}
                        format={"dd.MM.yyyy HH:mm"}
                        
                    />
                </MuiGrid>
                <MuiGrid item>
                    < DateTimePicker
                        disableFuture
                        slotProps={{ textField: { variant: 'outlined' } }}
                        label={t("object-sessions.endDate")}
                        value={endDate || endDateLocal}
                        onChange={(newVal) => {
                            if (!newVal) return;
                            if (setEndDate) setEndDate(newVal);
                            setEndDateLocal(newVal);
                        }}
                        format={"dd.MM.yyyy HH:mm"}
                        
                    />
                </MuiGrid>
            </MuiGrid>
            <Grid
                rows={rows}
                columns={columns}
                getRowId={(x)=>x.id}
            >
                <FilteringState
                    filters={filters}
                    onFiltersChange={setFilters}
                />
                <IntegratedFiltering />

                <SortingState
                />
                <IntegratedSorting />

                <PagingState
                    currentPage={currentPage}
                    onCurrentPageChange={setCurrentPage}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                />
                <SelectionState selection={selectedRows.map(x => x.id)} onSelectionChange={(ids) => {
                    setSelectedRows(rows.filter(x => ids.includes(x.id)));
                }} />
                <IntegratedSelection />
                <IntegratedPaging />

                <Table cellComponent={Cell} />
                <TableColumnResizing
                    columnWidths={columnWidths}
                    onColumnWidthsChange={setColumnWidths as any}
                />
                <TableHeaderRow showSortingControls />
                <TableColumnVisibility />
                <Toolbar />
                <ExportPanel startExport={startExport} />
                <ColumnChooser />
                <TableFilterRow />
                <TableSelection showSelectAll />
                <PagingPanel pageSizes={[10, 15, 0]} />
            </Grid>
            <GridExporter
                ref={exporterRef}
                customizeCell={customizeCell}
                filters={filters}
                rows={rows}
                columns={columns}
                onSave={onSave}
            />
        </Paper>
    );
};

const useStyles = makeStyles({
    selected: {
        backgroundColor: lightgrey
    },
    customRow: {
        cursor: 'pointer',
        "&:hover": {
            backgroundColor: lightgrey
        }
    }
});