import './App.css';
import './css/grid.css';
import './css/container.css';
import './css/gridstackjs.css';
import './css/font.css';
import './css/tableSmall.css';
import './css/public/feedback.css';
import Login from './components/unauthorized/Login';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ThemeProvider, Theme, StyledEngineProvider, adaptV4Theme, CssBaseline, responsiveFontSizes } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import DateFnsUtils from '@date-io/date-fns';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PasswordForgottenPage } from './components/unauthorized/PasswordForgotten';
import { ResetPasswordPage } from './components/unauthorized/ResetPassword';
import { ChangeUserEmailPage } from './components/unauthorized/ChangeUserEmailPage';
import { PortalPage } from './components/authorized/PortalPage';
import { SignUpPage } from './components/unauthorized/SignUpPage';
import { ShopPagePublic } from './components/unauthorized/ShopPagePublic';
import { green } from '@mui/material/colors';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import deAtLocale from 'date-fns/locale/de-AT';
import DigitalCheckpointConfig from './components/unauthorized/DigitalCheckpointConfig';
import { Instructions } from './components/authorized/blobs/Instructions';
import { FeedbackPublic } from './components/unauthorized/PublicFeedback';
import { News } from './components/authorized/portal/News';
import { NewsPublic } from './components/unauthorized/NewsPublic';
import { IntlProvider } from 'react-intl';
import React from 'react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import de from './translations/de.json'
import en from './translations/en.json'
import detector from "i18next-browser-languagedetector";
import { PublicServiceLogin, RootPagePublicService } from './components/unauthorized/PublicService';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme { }
}


i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: en
      },
      de: {
        translation: de
      }
    },
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });


export const primaryColor = '#1f367a';
const theme = responsiveFontSizes(createTheme({
  typography: {
    htmlFontSize: 10,
    fontSize: 10
  },
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: green[500],
    },
  },
}));

export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_PUBLIC_KEY || "");
const messagesInFrench = {
  myMessage: "Aujourd'hui, c'est le {ts, date, ::yyyyMMdd}",
}
export const App = () => {
  return (
    <Elements stripe={stripePromise}>
      <IntlProvider messages={messagesInFrench} locale="de" defaultLocale="de">
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deAtLocale}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <SnackbarProvider maxSnack={20} autoHideDuration={8000}>
                <Router>
                  <Switch>
                    <Route exact path={`/instructions`} key={document.location.href}>
                      <Instructions />
                    </Route>
                    <Route path={`/news`} key={document.location.href}>
                      <NewsPublic />
                    </Route>
                    <Route exact path="/dc-config" component={DigitalCheckpointConfig} />
                    <Route path="/portal" component={PortalPage} />
                    <Route exact path={`/feedback/public`} key={document.location.href} component={FeedbackPublic} />
                    <Route exact path={`/service/public`} key={document.location.href} component={RootPagePublicService} />

                    <Route path={`/shop/public`} key={document.location.href} component={ShopPagePublic} />
                    <Route exact path="/login/userId/:userId/tokenId/:tokenId" component={Login} />
                    <Route exact path="/login" component={Login} />
                    <Route path="/register" component={SignUpPage} />
                    <Route path="/password-forgotten" component={PasswordForgottenPage} />
                    <Route path="/reset-password/:requestId" component={ResetPasswordPage} />
                    <Route path="/change-user-email/users/:userId/requests/:requestId" component={ChangeUserEmailPage} />
                    <Route path="/" component={Login} />
                  </Switch>
                </Router>
              </SnackbarProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </IntlProvider>
    </Elements>
  );
}

export default App;
