import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Backdrop,
    Button,
    CircularProgress,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    InputAdornment,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Paper,
    TextField,
    Theme,
    Typography,
    useTheme,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ShoppingCart, MergeType, ExpandMore, VpnKey, Add, TouchApp, Navigation, Close, Room, Battery6Bar, Battery90, Hub, Router, SupervisedUserCircle, FeaturedVideo, TabletAndroid, HowToReg, SettingsInputHdmi, CleanHands, AddTask, Map, LibraryAdd, QrCodeScanner, Visibility, QrCode, Sensors, Cancel, Publish, Key, ExpandLess, Bluetooth, CalendarMonth, LockOpen, Wifi, RouterOutlined, EdgesensorHigh, WifiPassword, UploadFile, FileDownload, MapOutlined, MapSharp, Watch, AlarmOn } from "@mui/icons-material";
import React, { useContext } from "react";
import { api } from "../../../api";
import { getApiToken, setStorageForDrawer } from "../../../helpers/storage-management";
import { GroupLicenseTable, SensorLicense } from "../../../models/GroupLicenseTable";
import { GroupLicense } from "../../../models/GroupLicense";
import { useHistory } from "react-router-dom";

import { useSnackbar } from "notistack";
import { UserKeysDialog } from "../../dialogs/UserKeys";
import { UserKey } from "../../../models/UserKey";
import { AddUserKeyDialog } from "../../dialogs/AddUserKey";
import { handleSelectedCombinationGroup } from "../../tables/Base";
import { green, red, white, yellow } from "../../../styles/colors";
import { UserAccount } from "../../../models/UserAccount";
import { UserType } from "../../../models/UserType";
import { UserInvoice } from '../../../models/UserInvoice';
import { LicenseType } from '../../../models/LicenseType';
import { AssignLicenseChoosingTableDialog, ShowCountType } from '../../dialogs/AssignLicenseChoosingTableDialog';
import { LoadingIcon } from "../../common/LoadingIcon";
import { useQuery } from "./checkout/ShopPage";
import { getUserIdFromStorage } from "../../../helpers/common";
import { Group, GroupType } from "../../../models/Group";
import { CheckpointGroupEditingDialog } from "../../dialogs/CheckpointGroupEditingDialog";
import { GroupSettings, ToiletType } from "../../../models/GroupSettings";
import { CheckpointSelectionDialog } from "../extendedapp/checkpoint/CheckpointSelectionDialog";
import { GlobalContext } from "../PortalPage";
import { AddHubDialog } from "../../dialogs/licenseManagement/AddHubDialog";
import { BatteryDisplayDialogChoice, BatteryDisplaySelectionDialog } from "../extendedapp/batteryDisplay/BatteryDisplaySelectionDialog";
import { BssdSelectionDialog } from "../extendedapp/bssdHub/BssdSelectionDialog";
import { HubModel } from "../../../models/HubModel";
import { SsdUnregisteredDevicesDialog } from "../extendedapp/bssdHub/SsdUnregisteredDevicesDialog";
import { LicenseSvgGrey, LicenseWhite } from "../../../assets";
import { SsdDeviceModel } from "../../../models/bssd/SsdDeviceModel";
import { RegisteredDevicesSelectionDialog } from "../extendedapp/batteryDisplay/RegisteredDevicesSelectionDialog";
import { BssdRegisteredDeviceModel } from "../../../models/bssd/BssdRegisteredDeviceModel";
import { LicenseManagementTouchDisplayDialog, TouchDisplayDialogChoice, TouchDisplayLicenseActionChoice } from "./dialog/LicenseManagementTouchDisplayDialog";
import { TorkDevicesDialog } from "../extendedapp/torkDevices/TorkDevicesDialog";
import { TorkDevicesLocations } from "../extendedapp/torkDevices/TorkDevicesLocations";
import { AddLoraDeviceDialog } from "../../dialogs/licenseManagement/AddLoraDeviceDialog";
import { LoraDeviceModel } from "../../../models/lora/LoraDeviceModel";
import { LoraDevicesSelectionDialog } from "../../dialogs/licenseManagement/LoraDevicesSelectionDialog";
import { DefaultDialogTitle } from "../../dialogs/DefaultDialogTitle";
import { stringify } from "querystring";
import { ImportDataButtonCount, ImportDataCount } from "../extendedapp/ObjectManagementPageDataTable";
import { schemaImportCheckpoint } from "../../../helpers/excel/schemas";
import { ReactComponent as MicrosofExcel } from '../../../assets/svgs/microsoft-excel.svg'
import TouchDisplayPng from '../../../assets/image/license/touchdisplay_license.png'
import BatteryDisplayPng from '../../../assets/image/license/batterydisplay_license.png'
import CheckpointPng from '../../../assets/image/license/checkpoint_license.png'
import TaqtDevicePng from '../../../assets/image/license/taqtdevice.png'
import TaqtTimeDevicePng from '../../../assets/image/license/taqttime.png'

import LoraPng from '../../../assets/image/license/lora_license.png'
import TorkPng from '../../../assets/image/license/tork_license.png'
import AdvertismentPng from '../../../assets/image/shop/cmn-content.png'
import SensorDevicePng from '../../../assets/image/shop/cmn-sensor.png'

import PayLicense from '../../../assets/image/license/pay_license.png'
import BssdLicense from '../../../assets/image/license/bssd_license.png'
import BssdSensorLicense from '../../../assets/image/license/bssdsensor_license.png'
import BssdHubLicense from '../../../assets/image/license/bssdhub_license.png'

import SmileGreenPng from '../../../assets/image/smiles/green.png'
import { AllLicensedGroups, AllLicensedGroupsDialogType } from "./LicenseManagement";
import useNoInitialEffect from "../../../helpers/extensions";
import { ObjectUnit } from "../../../models/ObjectUnit";
export const LicenseAndAddGroupComponent = ({
    selectedCombinationGroup,
    setSelectedCombinationGroup,
    userAccount,
    selectedGroupId }
    : {
        selectedCombinationGroup: GroupLicenseTable[],
        setSelectedCombinationGroup: any,
        userAccount: UserAccount | undefined,
        selectedGroupId: string
    }) => {

    const history = useHistory();
    const theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const globals: any = useContext(GlobalContext);
    const { filtersGroupLicenseTable, setFiltersGroupLicenseTable }: { filtersGroupLicenseTable: any[], setFiltersGroupLicenseTable: any } = React.useContext(GlobalContext);


    const [allLicensedGroupsDialogType, setAllLicensedGroupsDialogType] = React.useState(AllLicensedGroupsDialogType.None);

    const [token, setToken] = React.useState("");
    const [backdropLoading, setBackdropLoading] = React.useState(false);
    const [checkpointCountAdd, setCheckpointCountAdd] = React.useState(1);
    const [freeLicenses, setFreeLicenses] = React.useState<GroupLicense[]>([]);
    const [checkpointLicenses, setCheckpointLicenses] = React.useState<GroupLicense[]>([]);
    const [qualityCheckLicenses, setQualityLicenses] = React.useState<GroupLicense[]>([]);
    const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);
    const [userKeys, setUserKeys] = React.useState<UserKey[]>([]);
    const [selectedGroups, setSelectedGroups] = React.useState<GroupLicenseTable[]>([]);
    const [selectedGroup, setSelectedGroup] = React.useState<GroupLicenseTable | null>(null);
    const [invoices, setInvoices] = React.useState<UserInvoice[]>([]);

    const [isTouchDisplayManagementVisible, setIsTouchDisplayManagementVisible] = React.useState(false);

    const [openUserKeysDialog, setOpenUserKeysDialog] = React.useState(false);
    const [openAddUserKeysDialog, setOpenAddUserKeysDialog] = React.useState(false);

    const [openAddHubDialog, setOpenAddHubDialog] = React.useState(false);

    const [openBssdRegisteredHubsDialog, setOpenBssdRegisteredHubsDialog] = React.useState(false);
    const [openSsdUnregisteredDevicesDialog, setOpenSsdUnregisteredDevicesDialog] = React.useState(false);


    const [openSsdRegisteredDevicesDialog, setOpenSsdRegisteredDevicesDialog] = React.useState(false);

    const [bssdGroupEditDialogVisible, setBssdGroupEditDialogVisible] = React.useState(false);
    const [currentSelectedBssdGroup, setCurrentSelectedBssdGroup] = React.useState<GroupSettings>();
    const [assignBssdLicenseDialogVisible, setAssignBssdLicenseDialogVisible] = React.useState(false);

    const [assignCheckpointLicenseDialogVisible, setAssignCheckpointLicenseDialogVisible] = React.useState(false);
    const [assignCheckpointLicenseExtentedDialogVisible, setAssignCheckpointLicenseExtentedDialogVisible] = React.useState(false);



    const [assignTaqtFeedbackLicenseDialogVisible, setAssignTaqtFeedbackLicenseDialogVisible] = React.useState(false);
    const [currentSelectedTaqtFeedbackGroup, setCurrentSelectedTaqtFeedbackGroup] = React.useState<GroupSettings>();
    const [taqtFeedbackGroupEditDialogVisible, setTaqtFeedbackGroupEditDialogVisible] = React.useState(false);
    const [assignTaqtFeedbackLicenseExtentedDialogVisible, setAssignTaqtFeedbackLicenseExtentedDialogVisible] = React.useState(false);
    const [taqtFeedbackLicenses, setTaqtFeedbackLicenses] = React.useState<GroupLicense[]>([]);

    const [taqtFeedbackSelectionDialogVisible, setTaqtFeedbackSelectionDialogVisible] = React.useState(false);
    const [currentSelectedGroupTaqtFeedbackId, setCurrentSelectedGroupTaqtFeedbackId] = React.useState<string>("");

    const [assignTaqtTimeLicenseDialogVisible, setAssignTaqtTimeLicenseDialogVisible] = React.useState(false);
    const [currentSelectedTaqtTimeGroup, setCurrentSelectedTaqtTimeGroup] = React.useState<GroupSettings>();
    const [taqtTimeGroupEditDialogVisible, setTaqtTimeGroupEditDialogVisible] = React.useState(false);
    const [assignTaqtTimeLicenseExtentedDialogVisible, setAssignTaqtTimeLicenseExtentedDialogVisible] = React.useState(false);
    const [taqtTimeLicenses, setTaqtTimeLicenses] = React.useState<GroupLicense[]>([]);

    const [taqtTimeSelectionDialogVisible, setTaqtTimeSelectionDialogVisible] = React.useState(false);
    const [currentSelectedGroupTaqtTimeId, setCurrentSelectedGroupTaqtTimeId] = React.useState<string>("");

    const [openLoraDeviceDialog, setOpenLoraDevicesDialog] = React.useState(false);
    const [openAddLoraDeviceDialog, setOpenAddLoraDeviceDialog] = React.useState(false);
    const [loraDevices, setLoraDevices] = React.useState<LoraDeviceModel[]>([]);
    const [isLoraDevicesLoading, setIsLoraLoading] = React.useState<boolean | undefined>(undefined);

    //multiple dialog


    const [checkpointEditDialogVisible, setCheckpointEditDialogVisible] = React.useState(false);
    const [currentSelectedCheckpoint, setCurrentSelectedCheckpoint] = React.useState<GroupSettings>();
    const [currentSelectedCheckpointId, setCurrentSelectedCheckpointId] = React.useState<string>("");
    const [loading, setLoading] = React.useState(false);
    const [isInvoicesLoading, setIsInvoicesLoading] = React.useState<boolean | undefined>(undefined);
    const [isUserKeysLoading, setIsUserKeysLoading] = React.useState<boolean | undefined>(undefined);
    const [isBatteryDisplayLoading, setIsBatteryDisplayLoading] = React.useState<boolean | undefined>(undefined);

    const handleUserKeysClose = () => setOpenUserKeysDialog(false);
    const chosenUnit = useQuery(history).get("chosenUnit");
    const navigationForAddingCheckpoint = useQuery(history).get("navigationForAddingCheckpoint");
    const [isAccordionExpandedLicenseTable, setIsAccordionExpandedLicenseTable] = React.useState(false);
    const [isAllGroupsAccordionExpandedLicenseTable, setIsAllGroupsAccordionExpandedLicenseTable] = React.useState(false);

    const [checkpointSelectionDialogVisible, setCheckpointSelectionDialogVisible] = React.useState(false);

    const [batteryDisplaySelectionDialogVisible, setBatteryDisplaySelectionDialogVisible] = React.useState(false);
    const [hubs, setHubs] = React.useState<HubModel[]>([]);
    const [devices, setDevices] = React.useState<SsdDeviceModel[]>([]);
    const [registeredDevices, setRegisteredDevices] = React.useState<BssdRegisteredDeviceModel[]>([]);
    const [currentSelectedBssdDeviceId, setCurrentSelectedBssdDeviceId] = React.useState<string>("");
    const [currentSelectedBssdGroupLicenseTable, setCurrentSelectedBssdGroupLicenseTable] = React.useState<GroupLicenseTable>();


    const [openTorkDevicesDialog, setOpenTorkDevicesDialog] = React.useState(false);
    const [openTorkLocationsDialog, setOpenTorkLocationsDialog] = React.useState(false);

    const [assignMultipleCheckpoints, setAssignMultipleCheckpoints] = React.useState(false);
    const [openAllGroupsLicenseDialog, setOpenAllGroupsLicenseDialog] = React.useState(false);

    const [allLicenses, setAllLicenses] = React.useState<GroupLicense[]>([]);

    const objects = React.useRef<ObjectUnit[]>([]);




    useNoInitialEffect(() => {
        setSelectedGroups([]);
    }, [openAllGroupsLicenseDialog]);

    const [currentTouchDisplayLicenseActionChoice, setCurrentTouchDisplayLicenseActionChoice] = React.useState(TouchDisplayLicenseActionChoice.None);

    const styleImg = { height: 50, marginRight: 10, width: 90, objectFit: 'contain' } as any;

    const assignBssdDisplayLicense = (deviceId: string) => {
        setCurrentSelectedBssdDeviceId(deviceId);
        setAssignBssdLicenseDialogVisible(true);
    }

    const loadLoraData = async () => {
        const tempUserKeys = await api.getLoraDevices(token);
        setLoraDevices(tempUserKeys);
    }

    const loadDataAsync = async () => {

        const tempToken = getApiToken(history);
        if (!tempToken) return;
        setToken(tempToken);

        setSelectedGroup(null);
        setSelectedGroups([]);
        setLoading(true);

        const licenses = await api.getLicensesAsync(tempToken);
        setFreeLicenses(licenses.filter(el => !el.isUsed && el.type != LicenseType.ObjectNotificationLog));
        setCheckpointLicenses(licenses.filter(el => (el.checkpointCount > 0)));
        setTaqtFeedbackLicenses(licenses.filter(el => (el.taqtFeedbackCount > 0)));
        setTaqtTimeLicenses(licenses.filter(el => (el.taqtTimeCount > 0)));
        setQualityLicenses(licenses.filter(el => el.qualityCheckCount > 0));
        setAllLicenses(licenses);

        const tempGroups = await api.getAllGroups(tempToken);
        setGroups(tempGroups);
        let isGroupPreChosen = tempGroups.find(el => el.id == chosenUnit);
        if (isGroupPreChosen) {
            setSelectedGroup(isGroupPreChosen);
            setSelectedGroups([isGroupPreChosen]);
        }




        setLoading(false);
    }

    React.useEffect(() => {

        (async () => {
            await loadDataAsync();

            if (navigationForAddingCheckpoint) {
                setAssignMultipleCheckpoints(false);
                addCheckpoint();
            }
        })();
    }, []);

    React.useEffect(() => {
        setSelectedGroups(selectedCombinationGroup.map((el: any) => el.id));
    }, [selectedCombinationGroup]);




    const navigateToGroup = (currentGroup: GroupLicenseTable) => {

        globals.onUpdateDisplayGroup(currentGroup);

    }

    const navigateByLicenseTable = (selectedGroup: GroupLicenseTable) => {
        if (!selectedGroup) return;
        navigateToGroup(selectedGroup);
    }

    const addTaqtFeedbackDevice = React.useCallback(() => {
        if (userAccount?.userType == UserType.Customer || userAccount?.userType == UserType.ObjectCustomer) {
            enqueueSnackbar("Kunde kann nicht Touchless-Feedback-Gerät hinzufügen. Wenden Sie sich an den Besitzer!", { variant: 'error' });
            return;
        }

        setAssignTaqtFeedbackLicenseDialogVisible(true);
    }, [userAccount, setAssignTaqtFeedbackLicenseDialogVisible])

    const addTaqtTimeDevice = React.useCallback(() => {
        if (userAccount?.userType == UserType.Customer || userAccount?.userType == UserType.ObjectCustomer) {
            enqueueSnackbar("Kunde kann nicht Touchless-Feedback-Gerät hinzufügen. Wenden Sie sich an den Besitzer!", { variant: 'error' });
            return;
        }

        setAssignTaqtTimeLicenseDialogVisible(true);
    }, [userAccount, setAssignTaqtTimeLicenseDialogVisible])

    const addCheckpoint = React.useCallback(() => {
        if (userAccount?.userType == UserType.Customer || userAccount?.userType == UserType.ObjectCustomer) {
            enqueueSnackbar("Kunde kann nicht Checkpoint hinzufügen. Wenden Sie sich an den Besitzer!", { variant: 'error' });
            return;
        }

        setAssignCheckpointLicenseDialogVisible(true);
    }, [userAccount, setAssignCheckpointLicenseDialogVisible])

    const createCheckpoint = async (userId: string, selectedLicense: GroupLicense, notificationName: string, levelName: string, objectName: string, customerName: string) => {
        let res = await api.createGroupCheckpoint(userId, selectedLicense.id, token, enqueueSnackbar);
        return await api.createGroupSettings(GroupSettings.CreateCheckpointSetting(notificationName, objectName, levelName, customerName, res.id), token, enqueueSnackbar);
    }

    const loadBssdDevices = async () => {
        setIsBatteryDisplayLoading(true);
        const tempRegisteredDevices = await api.getBssdRegisteredDevices(token);
        setRegisteredDevices(tempRegisteredDevices);

        const tempHubs = await api.getBssdRegisteredHubs(token);
        setHubs(tempHubs);

        const tempDevices = await api.getBssdUnregisteredDevices(token);
        setDevices(tempDevices);
        setIsBatteryDisplayLoading(false);
    }

    const buildDefaultIMenutemLicenses = (groupType: GroupType) => {
        return (
            <>
                <ListItem>
                    <ListItemButton onClick={() => {
                        setFiltersGroupLicenseTable([{ columnName: GroupLicenseTable.Columns.groupType, value: Group.GetLabelByType(groupType) }])
                        setAllLicensedGroupsDialogType(AllLicensedGroupsDialogType.QualityCheck);
                        setOpenAllGroupsLicenseDialog(true);
                    }}>
                        <ListItemIcon>
                            <LicenseSvgGrey />
                        </ListItemIcon>
                        <ListItemText primary="Qualitätsmanagement-Lizenz zuweisen" />

                    </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemButton onClick={() => {
                        setFiltersGroupLicenseTable([{ columnName: GroupLicenseTable.Columns.groupType, value: Group.GetLabelByType(groupType) }])
                        setAllLicensedGroupsDialogType(AllLicensedGroupsDialogType.Lora);
                        setOpenAllGroupsLicenseDialog(true);
                    }}>
                        <ListItemIcon>
                            <img src={LoraPng} style={{ height: 30, width: 40 }} />
                        </ListItemIcon>
                        <ListItemText primary="LoRa-Sensor-Lizenz zuweisen" />
                    </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemButton onClick={() => {
                        setOpenLoraDevicesDialog(true);
                    }}>
                        <ListItemIcon>
                            <img src={SensorDevicePng} style={{ height: 40, width: 40 }} />

                        </ListItemIcon>
                        <ListItemText primary="LoRa-Sensor einer Einheit zuweisen" />

                    </ListItemButton>
                    <Grid item>
                        <Typography variant="body1" component="p" color="textSecondary">Lorageräte</Typography >
                    </Grid>
                    <Grid item>
                        <Typography className="Paper-Result" component="p">{loraDevices.length}</Typography >
                    </Grid>
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemButton onClick={() => {
                        setFiltersGroupLicenseTable([{ columnName: GroupLicenseTable.Columns.groupType, value: Group.GetLabelByType(groupType) }])
                        setAllLicensedGroupsDialogType(AllLicensedGroupsDialogType.CalendarPro);
                        setOpenAllGroupsLicenseDialog(true);
                    }}>
                        <ListItemIcon>
                            <CalendarMonth />
                        </ListItemIcon>
                        <ListItemText primary="KalenderPRO Lizenz zuweisen" />
                    </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemButton onClick={() => {
                        setFiltersGroupLicenseTable([{ columnName: GroupLicenseTable.Columns.groupType, value: Group.GetLabelByType(groupType) }])
                        setAllLicensedGroupsDialogType(AllLicensedGroupsDialogType.PublicFeedback);
                        setOpenAllGroupsLicenseDialog(true);
                    }}>
                        <ListItemIcon>
                            <img src={SmileGreenPng} style={{ height: 30, width: 30 }} />
                        </ListItemIcon>
                        <ListItemText primary="Public-Feedback Lizenz zuweisen" />
                    </ListItemButton>
                </ListItem>
                <Divider />
                <ListItem>
                    <ListItemButton onClick={() => {
                        setFiltersGroupLicenseTable([{ columnName: GroupLicenseTable.Columns.groupType, value: Group.GetLabelByType(groupType) }])
                        setAllLicensedGroupsDialogType(AllLicensedGroupsDialogType.PayPerService);
                        setOpenAllGroupsLicenseDialog(true);
                    }}>
                        <ListItemIcon>
                            <img src={PayLicense} style={{ height: 30, width: 30 }} />
                        </ListItemIcon>
                        <ListItemText primary="Pay Per Service Lizenz zuweisen" />
                    </ListItemButton>
                </ListItem>
            </>
        )
    }

    const buildTorkDevicePanel = () => {
        return (
            <Grid item xs={12} lg={12}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}
                    >
                        <img src={TorkPng} style={styleImg} />
                        <Typography component="h4" variant="h4">Tork-Geräte (Waschraumhygiene)</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List
                            sx={{ width: '100%', bgcolor: 'background.paper' }}
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    Tork-Geräte
                                </ListSubheader>
                            }
                        >
                            <ListItem>
                                <ListItemButton onClick={() => {
                                    //    setOpenAddLoraDeviceDialog(true);
                                }}>
                                    <ListItemIcon>
                                        <Add />
                                    </ListItemIcon>
                                    <ListItemText primary="Torkgerät Hinzufügen" />

                                </ListItemButton>
                                <Grid item>
                                    <Typography variant="body1" component="p" color="textSecondary">Verfügbare Lizenzen</Typography >
                                </Grid>
                                <Grid item>
                                    <Typography className="Paper-Result" component="p">{freeLicenses.filter(x => x.type == LicenseType.Checkpoint).reduce((prevVal, curVal) => curVal.checkpointCount + prevVal, 0)}</Typography >
                                </Grid>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemButton onClick={() => {
                                    setOpenTorkDevicesDialog(true);
                                }}>
                                    <ListItemIcon>
                                        <Visibility />
                                    </ListItemIcon>
                                    <ListItemText primary="Torkgeräte Anzeigen" />

                                </ListItemButton>
                                <Grid item>
                                    <Typography variant="body1" component="p" color="textSecondary">Torkgeräte</Typography >
                                </Grid>
                                <Grid item>
                                    <Typography className="Paper-Result" component="p">2</Typography >
                                </Grid>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemButton onClick={() => {
                                    setOpenTorkLocationsDialog(true);
                                }}>
                                    <ListItemIcon>
                                        <MapSharp />
                                    </ListItemIcon>
                                    <ListItemText primary="Tork Locations Anzeigen" />

                                </ListItemButton>
                            </ListItem>
                            <Divider />
                        </List>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        )
    }

    const buildTaqtFeedbackPanel = () => {
        return (
            <Grid item xs={12} lg={12}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}
                    >
                        <img src={TaqtDevicePng} style={styleImg} />
                        <img src={SmileGreenPng} style={styleImg} />
                        <Typography component="h4" variant="h4">Touchless-Feedback-Geräte</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <List
                                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                                    subheader={
                                        <ListSubheader component="div" id="nested-list-subheader">
                                            Lizenz-Zuweisen
                                        </ListSubheader>
                                    }
                                >
                                    <ListItem>
                                        <ListItemButton onClick={() => {
                                            setAssignMultipleCheckpoints(false);
                                            addTaqtFeedbackDevice();
                                        }}>
                                            <ListItemIcon>
                                                <Add />
                                            </ListItemIcon>
                                            <ListItemText primary="Taqt Feedback Gerät hinzufügen und benennen" />
                                        </ListItemButton>
                                        {buildAvailableLicensesPanel(LicenseType.TaqtFeedback)}
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemButton onClick={() => { setTaqtFeedbackSelectionDialogVisible(true); }}>
                                            <ListItemIcon>
                                                <Visibility />
                                            </ListItemIcon>
                                            <ListItemText primary="Touchless-Feedback-Geräte und Device IDs anzeigen" />
                                            <Grid item>
                                                <Typography variant="body1" component="p" color="textSecondary">Taqt Feedback Geräte</Typography >
                                            </Grid>
                                            <Grid item>
                                                <Typography className="Paper-Result" component="p">{groups.filter(x => x.groupType == GroupType.TaqtFeedback).length}</Typography >
                                            </Grid>
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                    {buildDefaultIMenutemLicenses(GroupType.TaqtFeedback)}
                                    <Divider />
                                </List>
                            </Grid>
                        </Grid>

                    </AccordionDetails>
                </Accordion>
            </Grid>
        )
    }

    const buildTaqtTimePanel = () => {
        return (
            <Grid item xs={12} lg={12}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}
                    >
                        <img src={TaqtTimeDevicePng} style={styleImg} />
                        <AlarmOn color="primary" style={styleImg} />
                        <Typography component="h4" variant="h4">Touchless-Time-Geräte</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <List
                                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                                    subheader={
                                        <ListSubheader component="div" id="nested-list-subheader">
                                            Lizenz-Zuweisen
                                        </ListSubheader>
                                    }
                                >
                                    <ListItem>
                                        <ListItemButton onClick={() => {
                                            setAssignMultipleCheckpoints(false);
                                            addTaqtTimeDevice();
                                        }}>
                                            <ListItemIcon>
                                                <Add />
                                            </ListItemIcon>
                                            <ListItemText primary="Touchless-Time Gerät hinzufügen und benennen" />
                                        </ListItemButton>
                                        {buildAvailableLicensesPanel(LicenseType.TaqtTime)}
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemButton onClick={() => { setTaqtTimeSelectionDialogVisible(true); }}>
                                            <ListItemIcon>
                                                <Visibility />
                                            </ListItemIcon>
                                            <ListItemText primary="Touchless-Time-Geräte und Device IDs anzeigen" />
                                            <Grid item>
                                                <Typography variant="body1" component="p" color="textSecondary">Touchless-Time-Geräte</Typography >
                                            </Grid>
                                            <Grid item>
                                                <Typography className="Paper-Result" component="p">{groups.filter(x => x.groupType == GroupType.TaqtTime).length}</Typography >
                                            </Grid>
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                    {buildDefaultIMenutemLicenses(GroupType.TaqtTime)}
                                    <Divider />
                                </List>
                            </Grid>
                        </Grid>

                    </AccordionDetails>
                </Accordion>
            </Grid>
        )
    }

    const buildCheckpointsPanel = () => {
        return (

            <Grid item xs={12} lg={12}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}
                    >
                        <img src={CheckpointPng} style={styleImg} />
                        <Typography component="h4" variant="h4">Checkpoints</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <List
                                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                                    subheader={
                                        <ListSubheader component="div" id="nested-list-subheader">
                                            Lizenz-Zuweisen
                                        </ListSubheader>
                                    }
                                >
                                    <ListItem>
                                        <ListItemButton onClick={() => {
                                            setAssignMultipleCheckpoints(false);
                                            addCheckpoint();

                                        }}>
                                            <ListItemIcon>
                                                <Add />
                                            </ListItemIcon>
                                            <ListItemText primary="Checkpoint hinzufügen und benennen (einzeln)" />

                                        </ListItemButton>
                                        {buildAvailableLicensesPanel(LicenseType.Checkpoint)}
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemButton style={{ flexGrow: 0 }} onClick={() => {
                                            setAssignMultipleCheckpoints(true);
                                            addCheckpoint();
                                        }}>
                                            <ListItemIcon>
                                                <Add />
                                                <Add />
                                            </ListItemIcon>
                                            <ListItemText primary="Checkpoint hinzufügen (mehrfach)" />

                                        </ListItemButton>
                                        <ListItemText>
                                            <TextField
                                                size="small"
                                                required
                                                variant="outlined"
                                                label="Anzahl Checkpoints"
                                                type="number"
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end"><LibraryAdd /></InputAdornment>
                                                }}
                                                value={checkpointCountAdd}
                                                onChange={(event: any) => {
                                                    setCheckpointCountAdd(event.target.value);
                                                }}
                                            />
                                        </ListItemText>
                                        {buildAvailableLicensesPanel(LicenseType.Checkpoint)}
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ImportDataCount title="Import Checkpoints von Excel-Spreadsheet" schema={schemaImportCheckpoint} onFinish={async (row: any) => {


                                            const userId = getUserIdFromStorage();
                                            console.log(row, objects.current.length);
                                            if (objects.current.length < 1 && row["Objekt Name"]) {
                                                enqueueSnackbar("Getting suitable Objects")
                                                objects.current = await api.getUserObjectUnits(token);
                                            }

                                            for (let license of checkpointLicenses) {
                                                if (license.checkpointCount > 0) {
                                                    let object = objects.current.find(x => x.name == row["Objekt Name"]);
                                                    await createAdvancedCheckpoint(userId || "", license, row["Einheit Name"], row["Ebene"], row["Objekt Name"], object, row["Kunden Name"], GroupSettings.MapToiletType(row["Raum Typ"]), row["Nutzungsart"], row["Raumgröße"], token, enqueueSnackbar);
                                                    license.checkpointCount = license.checkpointCount - 1;
                                                    break;
                                                }
                                            } 

                                        }} />
                                        {buildAvailableLicensesPanel(LicenseType.Checkpoint)}
                                    </ListItem>
                                    <ListItem>
                                        <ListItemButton href="https://restroombird.blob.core.windows.net/excel-import-vorlage-portal/Vorlage-Import-Checkpoints.xlsx">
                                            <ListItemIcon>
                                                <FileDownload />
                                            </ListItemIcon>
                                            <Button >Vorlage Import Checkpoints Download</Button>
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemButton onClick={() => { setCheckpointSelectionDialogVisible(true); }}>
                                            <ListItemIcon>
                                                <Visibility />
                                            </ListItemIcon>
                                            <ListItemText primary="Checkpoint anzeigen" />
                                            <Grid item>
                                                <Typography variant="body1" component="p" color="textSecondary">Checkpoints</Typography >
                                            </Grid>
                                            <Grid item>
                                                <Typography className="Paper-Result" component="p">{groups.filter(x => x.groupType == GroupType.Checkpoint).length}</Typography >
                                            </Grid>
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                    {buildDefaultIMenutemLicenses(GroupType.Checkpoint)}
                                    <Divider />
                                </List>
                            </Grid>
                        </Grid>

                    </AccordionDetails>
                </Accordion>
            </Grid>
        )
    }


    const buildLoraDevicePanel = () => {
        const height = 130;
        return (
            <Grid item xs={12} lg={12}>
                <Accordion onChange={async (event, expanded) => {
                    if (expanded && userKeys.length < 1) {
                        setIsLoraLoading(true);
                        await loadLoraData();
                        setIsLoraLoading(false);
                    }

                }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <img src={LoraPng} style={styleImg} />
                        <Typography component="h4" variant="h4">LoRa - Sensor</Typography>
                        <br />
                        {isLoraDevicesLoading &&
                            <CircularProgress size={64} />
                        }
                    </AccordionSummary>
                    <AccordionDetails>
                        <List
                            sx={{ width: '100%', bgcolor: 'background.paper' }}
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    Lora
                                </ListSubheader>
                            }
                        >
                            <ListItem>
                                <ListItemButton onClick={() => {
                                    setOpenAddLoraDeviceDialog(true);
                                }}>
                                    <ListItemIcon>
                                        <Add />
                                    </ListItemIcon>
                                    <ListItemText primary="LoRa-Sensor hinzufügen" />

                                </ListItemButton>

                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemButton onClick={() => {
                                    setFiltersGroupLicenseTable([{ columnName: GroupLicenseTable.Columns.groupType, value: Group.GetLabelByType(GroupType.All) }])
                                    setAllLicensedGroupsDialogType(AllLicensedGroupsDialogType.Lora);
                                    setOpenAllGroupsLicenseDialog(true);
                                }}>
                                    <ListItemIcon>
                                        <img src={LoraPng} style={{ height: 30 }} />
                                    </ListItemIcon>
                                    <ListItemText primary="LoRa-Sensor-Lizenz einer Einheit zuweisen" />

                                </ListItemButton>
                                <Grid item>
                                    <Typography variant="body1" component="p" color="textSecondary">Verfügbare Lizenzen</Typography >
                                </Grid>
                                <Grid item>
                                    <Typography className="Paper-Result" component="p">{freeLicenses.filter(x => x.type == LicenseType.LoraSensorExtension).reduce((prevVal, curVal) => curVal.loraSensorCount + prevVal, 0)}</Typography >
                                </Grid>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemButton onClick={() => {
                                    setOpenLoraDevicesDialog(true);
                                }}>
                                    <ListItemIcon>
                                        <img src={SensorDevicePng} style={{ height: 40, width: 40 }} />

                                    </ListItemIcon>
                                    <ListItemText primary="LoRa-Sensor einer Einheit zuweisen" />

                                </ListItemButton>
                                <Grid item>
                                    <Typography variant="body1" component="p" color="textSecondary">Lorageräte</Typography >
                                </Grid>
                                <Grid item>
                                    <Typography className="Paper-Result" component="p">{loraDevices.length}</Typography >
                                </Grid>
                            </ListItem>
                            <Divider />
                        </List>
                    </AccordionDetails>
                </Accordion>
            </Grid>
        )
    }


    const [openKeyPanel, setOpenKeyPanel] = React.useState(false);
    const [openHubsPanel, setOpenHubsPanel] = React.useState(false);

    const buildUserKeysPanel = () => {
        return (
            <Grid item xs={12} lg={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <List
                            sx={{ width: '100%', bgcolor: 'background.paper' }}
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    Lizenz-Keys
                                </ListSubheader>
                            }
                        >
                            <ListItemButton onClick={async () => {
                                setIsUserKeysLoading(true);
                                if (userKeys.length < 1) {
                                    const tempUserKeys = await api.getUserKeys(token);
                                    setUserKeys(tempUserKeys);
                                }

                                setIsUserKeysLoading(false);
                                setOpenKeyPanel(!openKeyPanel);
                            }}>
                                <ListItemIcon>
                                    <Key />
                                </ListItemIcon>
                                <ListItemText primary="Lizenzkey für Einheiten-Inbetriebnahme" />
                                {openKeyPanel ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                            <Divider />
                            <Collapse in={openKeyPanel} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItemButton sx={{ pl: 4 }}
                                        onClick={async () => {

                                            if (userAccount?.userType == UserType.Manager) {
                                                enqueueSnackbar("Manager kann nicht Lizenz-Keys hinzufügen. Wenden Sie sich an den Besitzer!", { variant: 'error' });
                                                return;
                                            }

                                            setOpenAddUserKeysDialog(true);
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Add />
                                        </ListItemIcon>
                                        <ListItemText primary="Lizenz Key generieren" />
                                    </ListItemButton>
                                    <Divider />
                                    <ListItemButton sx={{ pl: 4 }} onClick={() =>
                                        setOpenUserKeysDialog(true)}
                                    >
                                        <ListItemIcon>
                                            <Visibility />
                                        </ListItemIcon>
                                        <ListItemText primary="Lizenz Key anzeigen" />

                                    </ListItemButton>
                                </List>
                            </Collapse>
                        </List>
                        <UserKeysDialog userKeys={userKeys} open={openUserKeysDialog} onClose={handleUserKeysClose} />
                        {isUserKeysLoading &&
                            <CircularProgress size={64} />
                        }
                        <List
                            sx={{ width: '100%', bgcolor: 'background.paper' }}
                            subheader={
                                <ListSubheader component="div" id="nested-list-subheader">
                                    Lizenz-Zuweisen
                                </ListSubheader>
                            }
                        >
                            <ListItem>
                                <ListItemButton style={{ flexGrow: 1 }} onClick={() => {
                                    setCurrentTouchDisplayLicenseActionChoice(TouchDisplayLicenseActionChoice.ExtendTouchDevice);
                                    setIsTouchDisplayManagementVisible(true);
                                }}>
                                    <ListItemIcon>
                                        <img src={TouchDisplayPng} style={{ height: 30 }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Touch Display - Lizenz erweitern" />
                                    <Grid item>
                                        <Typography variant="body1" component="p" color="textSecondary">Touch Displays: <span style={{ fontWeight: 'bold', fontSize: 24, color: 'black' }}>{groups.filter(x => x.groupType == GroupType.DisplayWallApp).length}</span >
                                            <br />Verfügbare-Lizenzen: <span style={{ fontWeight: 'bold', fontSize: 24, color: 'black' }}>{freeLicenses.filter(x => x.type == LicenseType.Device).length}</span>
                                        </Typography >
                                    </Grid>

                                </ListItemButton>

                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemButton onClick={() => {
                                    setCurrentTouchDisplayLicenseActionChoice(TouchDisplayLicenseActionChoice.ExtendSensor);
                                    setIsTouchDisplayManagementVisible(true);
                                }}>
                                    <ListItemIcon>
                                        <Bluetooth />
                                    </ListItemIcon>
                                    <ListItemText primary="Bluetooth-Sensor – Erweiterungslizenz zuweisen (zweiter bzw. dritter Sensor)" />
                                </ListItemButton>
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemButton onClick={() => {
                                    setCurrentTouchDisplayLicenseActionChoice(TouchDisplayLicenseActionChoice.ExtendAd)
                                    setIsTouchDisplayManagementVisible(true);
                                }}>
                                    <ListItemIcon>
                                        <img src={AdvertismentPng} style={{ height: 30, width: 40 }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Werbe-Lizenz zuweisen" />
                                </ListItemButton>
                            </ListItem>
                            <Divider />
                            {buildDefaultIMenutemLicenses(GroupType.DisplayWallApp)}
                            <Divider />
                        </List>
                    </Grid>
                </Grid>
            </Grid>
        )
    }


    const buildAvailableLicensesPanel = React.useCallback((licenseType: LicenseType) => {
        switch (licenseType) {
            case LicenseType.Checkpoint:
                return (
                    <>
                        <Grid item>
                            <Typography variant="body1" component="p" color="textSecondary">Verfügbare Checkpoint Lizenzen:</Typography >
                        </Grid>
                        <Grid item>
                            <Typography className="Paper-Result" component="p">{freeLicenses.filter(x => x.type == LicenseType.Checkpoint).reduce((prevVal, curVal) => curVal.checkpointCount + prevVal, 0)}</Typography >
                        </Grid>
                    </>
                )
            case LicenseType.TaqtFeedback:
                return (
                    <>
                        <Grid item>
                            <Typography variant="body1" component="p" color="textSecondary">Verfügbare Touchless-Feedback Lizenzen:</Typography >
                        </Grid>
                        <Grid item>
                            <Typography className="Paper-Result" component="p">{freeLicenses.filter(x => x.type == LicenseType.TaqtFeedback).reduce((prevVal, curVal) => curVal.taqtFeedbackCount + prevVal, 0)}</Typography >
                        </Grid>
                    </>
                )
            case LicenseType.TaqtTime:
                return (
                    <>
                        <Grid item>
                            <Typography variant="body1" component="p" color="textSecondary">Verfügbare Touchless-Time Lizenzen:</Typography >
                        </Grid>
                        <Grid item>
                            <Typography className="Paper-Result" component="p">{freeLicenses.filter(x => x.type == LicenseType.TaqtTime).reduce((prevVal, curVal) => curVal.taqtTimeCount + prevVal, 0)}</Typography >
                        </Grid>
                    </>
                )
        }
    }, [freeLicenses])
    const buildAllGroupsLicenseDialog = ({ open, setOpen, onRefresh }
        : { open: boolean, setOpen: any, onRefresh: any }) => {

        const handleClose = () => {
            setFiltersGroupLicenseTable([{ columnName: GroupLicenseTable.Columns.groupType, value: "" }]);
            setOpen(false)
        }

        return (
            <Dialog
                fullWidth
                maxWidth="xl"
                onClose={handleClose}
                open={open}
            >
                <DefaultDialogTitle
                    title="Alle Lizenzierte Einheiten"
                    handleClose={handleClose}
                />
                <DialogContent dividers>
                    <AllLicensedGroups licenses={allLicenses} allLicensedGroupsDialogType={allLicensedGroupsDialogType} loading={loading} groups={groups} selectedGroup={selectedGroup} navigateByLicenseTable={navigateByLicenseTable} selectedGroups={selectedGroups} setSelectedCombinationGroup={setSelectedCombinationGroup} freeLicenses={freeLicenses} setSelectedGroups={setSelectedGroups} setSelectedGroup={setSelectedGroup} loadDataAsync={loadDataAsync} setGroups={setGroups} qualityCheckLicenses={qualityCheckLicenses} />
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <Grid
            container
            direction="row"
            spacing={3}
        >
            <Grid item xs={12}>
                <Accordion expanded={!!chosenUnit || isAccordionExpandedLicenseTable} >
                    <AccordionSummary onClick={() => { setIsAccordionExpandedLicenseTable(!isAccordionExpandedLicenseTable) }} expandIcon={<ExpandMore />}                    >
                        <img src={TouchDisplayPng} style={styleImg} /> <Typography component="h4" variant="h4">Touch-Displays</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {loading && <LoadingIcon loading={loading} icon={null} />}
                        {buildUserKeysPanel()}
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid item xs={12} lg={12}>
                <Accordion onChange={async (event, expanded) => {
                    if (expanded) {
                        await loadBssdDevices();
                    }
                }}>
                    <AccordionSummary expandIcon={<ExpandMore />}>

                        <img src={BatteryDisplayPng} style={{ ...styleImg }} />
                        <Typography component="h4" variant="h4">
                            Batterie-Displays & Hubs {isBatteryDisplayLoading ? "(wird geladen)" : ""}
                        </Typography>


                    </AccordionSummary>
                    <AccordionDetails>
                        {isBatteryDisplayLoading &&
                            <CircularProgress size={64} />
                        }
                        <Grid container direction="column" spacing={3}>
                            <Grid item>
                                <List
                                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                                    subheader={
                                        <ListSubheader component="div" id="nested-list-subheader">
                                            Hubs
                                        </ListSubheader>
                                    }
                                >
                                    <ListItemButton onClick={async () => {

                                        setOpenHubsPanel(!openHubsPanel);
                                    }}>
                                        <ListItemIcon>
                                            <img src={BssdHubLicense} style={{ height: 40, width: 35 }} />
                                        </ListItemIcon>
                                        <ListItemText primary="HUB  Verwaltung" />
                                        {openHubsPanel ? <ExpandLess /> : <ExpandMore />}
                                    </ListItemButton>
                                    <Divider />
                                    <Collapse in={openHubsPanel} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItemButton sx={{ pl: 4 }}
                                                onClick={async () => {
                                                    setOpenAddHubDialog(true);
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <Add />
                                                </ListItemIcon>
                                                <ListItemText primary="HUB hinzufügen" />
                                            </ListItemButton>
                                            <Divider />
                                            <ListItemButton sx={{ pl: 4 }} onClick={() => setOpenBssdRegisteredHubsDialog(true)}>
                                                <ListItemIcon>
                                                    <Visibility />
                                                </ListItemIcon>
                                                <ListItemText primary="HUB anzeigen (registrierte)" />
                                                <Grid item>
                                                    <Typography variant="body1" component="p" color="textSecondary">Hubs</Typography >
                                                </Grid>
                                                <Grid item>
                                                    <Typography className="Paper-Result" component="p">{hubs.length}</Typography >
                                                </Grid>
                                            </ListItemButton>
                                        </List>
                                    </Collapse>
                                </List>

                                <List
                                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                                    subheader={
                                        <ListSubheader component="div" id="nested-list-subheader">
                                            Battery-Display
                                        </ListSubheader>
                                    }
                                >
                                    <ListItem>
                                        <ListItemButton onClick={() => { setOpenSsdUnregisteredDevicesDialog(true); }}>
                                            <ListItemIcon>
                                                <LockOpen />
                                            </ListItemIcon>
                                            <ListItemText primary="Unregistriertes Display reservieren" />
                                            <Grid item>
                                                <Typography variant="body1" component="p" color="textSecondary">Unregestrierte Displays</Typography >
                                            </Grid>
                                            <Grid item>
                                                <Typography className="Paper-Result" component="p">{devices.length}</Typography >
                                            </Grid>
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemButton onClick={() => { setOpenSsdRegisteredDevicesDialog(true); }}>
                                            <ListItemIcon>
                                                <img src={BssdLicense} style={{ height: 30 }} />
                                            </ListItemIcon>
                                            <ListItemText primary="Reserviertes Display als Einheit aktivieren" />
                                            <Grid item>
                                                <Typography variant="body1" component="p" color="textSecondary">Registrierte Displays</Typography >
                                            </Grid>
                                            <Grid item>
                                                <Typography className="Paper-Result" component="p">{groups.filter(x => x.groupType == GroupType.DisplayBatteryWallApp).length}</Typography >
                                            </Grid>
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemButton onClick={() => { setOpenSsdRegisteredDevicesDialog(true); }}>
                                            <ListItemIcon>
                                                <img src={BssdSensorLicense} style={{ height: 30 }} />
                                            </ListItemIcon>
                                            <ListItemText primary="WLAN-Sensor zu einer Einheit zuweisen" />
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemButton onClick={() => { setBatteryDisplaySelectionDialogVisible(true); }}>
                                            <ListItemIcon>
                                                <WifiPassword />
                                            </ListItemIcon>
                                            <ListItemText primary="WLAN-Sensor – Erweiterungslizenz zuweisen" />
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider />
                                    {buildDefaultIMenutemLicenses(GroupType.DisplayBatteryWallApp)}
                                    <Divider />
                                </List>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </Grid>


            {buildLoraDevicePanel()}

            {buildTorkDevicePanel()}

            {buildCheckpointsPanel()}

            {buildTaqtFeedbackPanel()}

            {buildTaqtTimePanel()}










            <AddUserKeyDialog
                open={openAddUserKeysDialog}
                setOpen={setOpenAddUserKeysDialog}
                setUserKeys={setUserKeys}
            />

            <AddHubDialog onRefresh={async () => { await loadDataAsync() }} open={openAddHubDialog} setOpen={setOpenAddHubDialog} />
            <AddLoraDeviceDialog onRefresh={async () => { await loadDataAsync() }} open={openAddLoraDeviceDialog} setOpen={setOpenAddLoraDeviceDialog} />
            <LoraDevicesSelectionDialog
                groups={groups}
                assignSensorLicense={() => {
                    setOpenLoraDevicesDialog(false);
                    setOpenAllGroupsLicenseDialog(true);
                }}
                open={openLoraDeviceDialog}
                setOpen={setOpenLoraDevicesDialog}
                onRefresh={async () => {
                    setIsLoraLoading(true);
                    await loadLoraData();
                    setIsLoraLoading(false);
                }}
                devices={loraDevices}
            />


            <AssignLicenseChoosingTableDialog
                showCountType={ShowCountType.BssdSensor}
                visible={assignBssdLicenseDialogVisible}
                licenses={freeLicenses.filter(x => x.type === LicenseType.BatteryDisplay)}
                onClose={() => {
                    setAssignBssdLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicense: GroupLicense) => {
                    const userId = getUserIdFromStorage();
                    if (!userId) return;
                    let res = await api.createGroupBssdDisplay(userId, currentSelectedBssdDeviceId, selectedLicense.id, token, enqueueSnackbar);
                    setAssignBssdLicenseDialogVisible(false);
                    let groupSettings = await api.createGroupSettings(GroupSettings.CreateBssdSetting("", "", "", "", res.id, currentSelectedBssdDeviceId), token, enqueueSnackbar);
                    setCurrentSelectedBssdGroup(groupSettings);
                    setBssdGroupEditDialogVisible(true);
                }}
            />

            <AssignLicenseChoosingTableDialog
                showCountType={ShowCountType.TaqtFeedback}
                visible={assignTaqtFeedbackLicenseDialogVisible}
                licenses={freeLicenses.filter(x => x.type === LicenseType.TaqtFeedback)}
                onClose={() => {
                    setAssignTaqtFeedbackLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicense: GroupLicense) => {
                    const userId = getUserIdFromStorage();
                    if (!userId) return;
                    let res = await api.createGroupTaqtFeedback(userId, selectedLicense.id, token, enqueueSnackbar);
                    setAssignTaqtFeedbackLicenseDialogVisible(false);
                    let groupSettings = await api.createGroupSettings(GroupSettings.CreateTaqtFeedbackSetting("", "", "", "", res.id), token, enqueueSnackbar);
                    setCurrentSelectedTaqtFeedbackGroup(groupSettings);
                    setTaqtFeedbackGroupEditDialogVisible(true);
                }}
            />

            <AssignLicenseChoosingTableDialog
                showCountType={ShowCountType.TaqtTime}
                visible={assignTaqtTimeLicenseDialogVisible}
                licenses={freeLicenses.filter(x => x.type === LicenseType.TaqtTime)}
                onClose={() => {
                    setAssignTaqtTimeLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicense: GroupLicense) => {
                    const userId = getUserIdFromStorage();
                    if (!userId) return;
                    let res = await api.createGroupTaqtTime(userId, selectedLicense.id, token, enqueueSnackbar);
                    setAssignTaqtTimeLicenseDialogVisible(false);
                    let groupSettings = await api.createGroupSettings(GroupSettings.CreateTaqtTimeSetting("", "", "", "", res.id), token, enqueueSnackbar);
                    setCurrentSelectedTaqtTimeGroup(groupSettings);
                    setTaqtTimeGroupEditDialogVisible(true);
                }}
            />

            <AssignLicenseChoosingTableDialog
                showCountType={ShowCountType.Checkpoint}
                visible={assignCheckpointLicenseDialogVisible}
                licenses={checkpointLicenses}
                onClose={() => {
                    setAssignCheckpointLicenseDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicense: GroupLicense) => {
                    const userId = getUserIdFromStorage();
                    if (!userId) return;
                    if (!assignMultipleCheckpoints) {
                        let res = await api.createGroupCheckpoint(userId, selectedLicense.id, token, enqueueSnackbar);
                        setAssignCheckpointLicenseDialogVisible(false);
                        setBackdropLoading(true);
                        let groupSettings = await api.createGroupSettings(GroupSettings.CreateCheckpointSetting("", "", "", "", res.id), token, enqueueSnackbar);
                        setBackdropLoading(false);
                        setCurrentSelectedCheckpoint(groupSettings);
                        setCheckpointEditDialogVisible(true);
                    } else {

                        let i = checkpointCountAdd;
                        while (i > 0) {
                            try {
                                await createCheckpoint(userId, selectedLicense, "", "", "", "");
                            } catch { }
                            i--;
                        }
                        enqueueSnackbar(`${checkpointCountAdd} Checkpoints wurden hinzugefügt`, { variant: 'success' });
                        setAssignCheckpointLicenseDialogVisible(false);
                    }

                }}
            />

            <AssignLicenseChoosingTableDialog
                showCountType={ShowCountType.Checkpoint}
                visible={assignCheckpointLicenseExtentedDialogVisible}
                licenses={checkpointLicenses}
                onClose={() => {
                    setAssignCheckpointLicenseExtentedDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicense: GroupLicense) => {
                    await api.assignLicenseToGroup(currentSelectedCheckpointId, selectedLicense, token, enqueueSnackbar);
                    setAssignCheckpointLicenseExtentedDialogVisible(false);
                }}
            />

            <AssignLicenseChoosingTableDialog
                showCountType={ShowCountType.TaqtFeedback}
                visible={assignTaqtFeedbackLicenseExtentedDialogVisible}
                licenses={taqtFeedbackLicenses}
                onClose={() => {
                    setAssignTaqtFeedbackLicenseExtentedDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicense: GroupLicense) => {
                    await api.assignLicenseToGroup(currentSelectedGroupTaqtFeedbackId, selectedLicense, token, enqueueSnackbar);
                    setAssignTaqtFeedbackLicenseExtentedDialogVisible(false);
                }}
            />

            <AssignLicenseChoosingTableDialog
                showCountType={ShowCountType.TaqtTime}
                visible={assignTaqtTimeLicenseExtentedDialogVisible}
                licenses={taqtTimeLicenses}
                onClose={() => {
                    setAssignTaqtTimeLicenseExtentedDialogVisible(false);
                }}
                onLicenseSelected={async (selectedLicense: GroupLicense) => {
                    await api.assignLicenseToGroup(currentSelectedGroupTaqtTimeId, selectedLicense, token, enqueueSnackbar);
                    setAssignTaqtTimeLicenseExtentedDialogVisible(false);
                }}
            />




            {currentSelectedBssdGroup &&
                <CheckpointGroupEditingDialog
                    defaultGroupSettings={currentSelectedBssdGroup}
                    onRefresh={loadDataAsync}
                    visible={bssdGroupEditDialogVisible}
                    setVisible={setBssdGroupEditDialogVisible}
                    group={currentSelectedBssdGroup}
                    onSave={async (newSettings: GroupSettings) => {

                        await api.updateGroupSettings(newSettings, token, enqueueSnackbar);
                        setBssdGroupEditDialogVisible(false);
                        await loadDataAsync();
                    }}
                />
            }

            {currentSelectedTaqtFeedbackGroup &&
                <CheckpointGroupEditingDialog
                    defaultGroupSettings={currentSelectedTaqtFeedbackGroup}
                    onRefresh={loadDataAsync}
                    visible={taqtFeedbackGroupEditDialogVisible}
                    setVisible={setTaqtFeedbackGroupEditDialogVisible}
                    group={currentSelectedTaqtFeedbackGroup}
                    onSave={async (newSettings: GroupSettings) => {

                        await api.updateGroupSettings(newSettings, token, enqueueSnackbar);
                        setTaqtFeedbackGroupEditDialogVisible(false);
                        await loadDataAsync();
                    }}
                />
            }

            {currentSelectedTaqtTimeGroup &&
                <CheckpointGroupEditingDialog
                    defaultGroupSettings={currentSelectedTaqtTimeGroup}
                    onRefresh={loadDataAsync}
                    visible={taqtTimeGroupEditDialogVisible}
                    setVisible={setTaqtTimeGroupEditDialogVisible}
                    group={currentSelectedTaqtTimeGroup}
                    onSave={async (newSettings: GroupSettings) => {

                        await api.updateGroupSettings(newSettings, token, enqueueSnackbar);
                        setTaqtTimeGroupEditDialogVisible(false);
                        await loadDataAsync();
                    }}
                />
            }

            {currentSelectedCheckpoint &&
                <CheckpointGroupEditingDialog
                    onRefresh={loadDataAsync}
                    visible={checkpointEditDialogVisible}
                    setVisible={setCheckpointEditDialogVisible}
                    group={currentSelectedCheckpoint}
                    onSave={async (newSettings: GroupSettings) => {

                        await api.updateGroupSettings(newSettings, token, enqueueSnackbar);
                    }}
                />
            }


            <CheckpointSelectionDialog
                groupType={GroupType.TaqtFeedback}
                open={taqtFeedbackSelectionDialogVisible}
                setOpen={setTaqtFeedbackSelectionDialogVisible}
                groups={groups.filter(x => x.groupType == GroupType.TaqtFeedback)}
                onEdit={async (group: GroupLicenseTable) => {
                    let settings = await api.getGroupSettings(group.id, token);
                    setCurrentSelectedTaqtFeedbackGroup(settings);
                    setTaqtFeedbackGroupEditDialogVisible(true);

                }}
                onExtendLicense={async (group: GroupLicenseTable) => {
                    setCurrentSelectedGroupTaqtFeedbackId(group.id);
                    setAssignTaqtFeedbackLicenseExtentedDialogVisible(true);
                }}
                onNavigate={(group: GroupLicenseTable) => {
                    navigateToGroup(group);
                }}
            />

            <CheckpointSelectionDialog
                groupType={GroupType.TaqtTime}
                open={taqtTimeSelectionDialogVisible}
                setOpen={setTaqtTimeSelectionDialogVisible}
                groups={groups.filter(x => x.groupType == GroupType.TaqtTime)}
                onEdit={async (group: GroupLicenseTable) => {
                    let settings = await api.getGroupSettings(group.id, token);
                    setCurrentSelectedTaqtTimeGroup(settings);
                    setTaqtTimeGroupEditDialogVisible(true);

                }}
                onExtendLicense={async (group: GroupLicenseTable) => {
                    setCurrentSelectedGroupTaqtTimeId(group.id);
                    setAssignTaqtTimeLicenseExtentedDialogVisible(true);
                }}
                onNavigate={(group: GroupLicenseTable) => {
                    navigateToGroup(group);
                }}
            />

            <CheckpointSelectionDialog
                open={checkpointSelectionDialogVisible}
                setOpen={setCheckpointSelectionDialogVisible}
                groupType={GroupType.Checkpoint}
                groups={groups.filter(x => x.groupType == GroupType.Checkpoint)}
                onEdit={async (group: GroupLicenseTable) => {
                    let settings = await api.getGroupSettings(group.id, token);
                    setCurrentSelectedCheckpoint(settings);
                    setCheckpointEditDialogVisible(true);

                }}
                onExtendLicense={async (group: GroupLicenseTable) => {
                    setCurrentSelectedCheckpointId(group.id);
                    setAssignCheckpointLicenseExtentedDialogVisible(true);
                }}
                onNavigate={(group: GroupLicenseTable) => {
                    navigateToGroup(group);
                }}
            />
            <TorkDevicesDialog open={openTorkDevicesDialog} setOpen={setOpenTorkDevicesDialog} />
            <TorkDevicesLocations open={openTorkLocationsDialog} setOpen={setOpenTorkLocationsDialog} />
            {hubs.length > 0 &&
                <BssdSelectionDialog open={openBssdRegisteredHubsDialog} setOpen={setOpenBssdRegisteredHubsDialog} hubs={hubs} />
            }
            <SsdUnregisteredDevicesDialog open={openSsdUnregisteredDevicesDialog} setOpen={setOpenSsdUnregisteredDevicesDialog} devices={devices}
                onFinish={async () => {
                    await loadDataAsync();
                    await loadBssdDevices();
                }} />
            <RegisteredDevicesSelectionDialog
                groups={groups}
                assignBssdSensorLicense={() => {
                    setOpenSsdRegisteredDevicesDialog(false);
                    setBatteryDisplaySelectionDialogVisible(true);
                }}
                assignBssdDisplayLicense={assignBssdDisplayLicense}
                open={openSsdRegisteredDevicesDialog}
                setOpen={setOpenSsdRegisteredDevicesDialog}
                onRefresh={async () => {
                    await loadBssdDevices();
                    await loadDataAsync();
                }}
                devices={registeredDevices}
                onFinish={() => {
                    setAssignBssdLicenseDialogVisible(true);
                }} />
            <BatteryDisplaySelectionDialog
                open={batteryDisplaySelectionDialogVisible}
                setOpen={setBatteryDisplaySelectionDialogVisible}
                groups={groups.filter(x => x.groupType == GroupType.DisplayBatteryWallApp)}
                freeLicenses={freeLicenses}
                loadData={async () => {
                    await loadBssdDevices();
                    await loadDataAsync();
                }}
                dialogChoice={BatteryDisplayDialogChoice.None}
                onEdit={async (group: GroupLicenseTable) => {
                    let settings = await api.getGroupSettings(group.id, token);
                    setCurrentSelectedBssdGroup(settings);
                    setBssdGroupEditDialogVisible(true);
                }}
                onNavigate={(group: GroupLicenseTable) => {
                    navigateToGroup(group);
                }}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropLoading}
                onClick={() => { }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {buildAllGroupsLicenseDialog({ open: openAllGroupsLicenseDialog, setOpen: setOpenAllGroupsLicenseDialog, onRefresh: loadDataAsync })}
            <LicenseManagementTouchDisplayDialog licenseActionChoice={currentTouchDisplayLicenseActionChoice} dialogChoice={TouchDisplayDialogChoice.None} visible={isTouchDisplayManagementVisible} setVisible={setIsTouchDisplayManagementVisible} groups={groups} setGroups={setGroups} freeLicenses={freeLicenses} handleSelectedCombinationGroup={handleSelectedCombinationGroup} navigateByLicenseTable={navigateByLicenseTable} loadDataAsync={loadDataAsync} />
        </Grid >
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    deleteButton: {
        borderColor: theme.palette.error.main,
        color: white,
        backgroundColor: theme.palette.error.main
    },
}));

export const createAdvancedCheckpoint = async (userId: string, selectedLicense: GroupLicense, notificationName: string, levelName: string, objectName: string, object: ObjectUnit | undefined, customerName: string, toiletType: ToiletType, roomType: string, roomSize: number, token: any, enqueueSnackbar: any) => {
    let res = await api.createGroupCheckpoint(userId, selectedLicense.id, token, enqueueSnackbar);
    let groupSettings = await api.createGroupSettings(GroupSettings.CreateAdvancedCheckpointSetting(notificationName, objectName, object, levelName, customerName, res.id, toiletType, roomType, roomSize), token, enqueueSnackbar);
    return groupSettings;
}