import React from 'react';
import {
    Alert,
    AlertTitle,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    InputAdornment,
    InputLabel,
    List,
    ListItem,
    ListItemButton,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Switch,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CenterFocusStrong, Check, Edit, LocalShipping, Mail, ManageAccounts, QrCode2, Save, Settings, SupervisedUserCircle, WindowOutlined } from '@mui/icons-material';
import { UserAccount } from '../../../models/UserAccount';
import { DefaultDialogTitle } from '../../dialogs/DefaultDialogTitle';
import { StringHelper } from '../../../helpers/string-helper';
import { usePortalAccountStyles } from '../../../styles/usePortalAccountStyles';
import { useSnackbar } from 'notistack';
import { UserType } from '../../../models/UserType';
import { Autocomplete } from '@mui/material';
import { ObjectDisplayType, ObjectUnit } from '../../../models/ObjectUnit';

import { api } from '../../../api';
import { useTranslation } from 'react-i18next';
import { CopyObjectUnitOption } from '../../../models/CopyObjectUnitOption';
import { ObjectSelectionDataTable } from './entireEval/ObjectSelectionDataTable';
import { ReactComponent as ChartBellCurve } from '../../../assets/svgs/chart-bell-curve.svg';
import { StockActionDoneFromType } from '../../../models/UserStock';

export const ObjectManagementPageEditCoyToOtherObjects = ({ currentObject, visible, setVisible, token, objects, groups }
    : { currentObject: ObjectUnit, visible: boolean, setVisible: any, token: any, objects: ObjectUnit[], groups: any[] }) => {

    const [copyObjectOptions, setCopyObjectOptions] = React.useState<CopyObjectUnitOption>(new CopyObjectUnitOption());

    const [selectedObjects, setSelectedObjects] = React.useState<ObjectUnit[]>([]);


    const classes = usePortalAccountStyles();
    const theme = useTheme();

    const { t } = useTranslation();

    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);

    const onClose = () => {
        setVisible(false);
    }
    const { enqueueSnackbar } = useSnackbar();




    return (
        <Dialog open={visible} onClose={onClose} fullScreen>
            <DefaultDialogTitle
                title={`Kopier Optionen`}
                handleClose={onClose}
            />

            <DialogContent>
                <Grid container direction="row" className={classes.container}>
                    <Grid container xs={12} lg={12} direction="row" spacing={3} item>
                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            <ListItem>
                                <ListItemButton onClick={() => {
                                    setCopyObjectOptions((prev) => {
                                        prev.objectSettings = !prev.objectSettings;
                                        return { ...prev };
                                    })
                                }}>
                                    <Grid container direction={"row"} spacing={3} alignItems="center">
                                        <Grid item>
                                            <Settings style={{ fontSize: 40 }} width={70} height={70} fill={"#000000"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography component="h4" variant="h4">Objekt - Einstellungen</Typography>
                                        </Grid>
                                        <Grid item style={{ flexGrow: 1 }}></Grid>
                                        <Grid item>
                                            <Switch
                                                edge="end"
                                                checked={copyObjectOptions.objectSettings}
                                            />
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton onClick={() => {
                                    setCopyObjectOptions((prev) => {
                                        prev.qmSettings = !prev.qmSettings;
                                        return { ...prev };
                                    })
                                }}>
                                    <Grid container direction={"row"} spacing={3} alignItems="center">
                                        <Grid item>
                                            <CenterFocusStrong style={{ fontSize: 40 }} width={70} height={70} fill={"#000000"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography component="h4" variant="h4">Qualitätsmanagement - Planung</Typography>
                                        </Grid>
                                        <Grid item style={{ flexGrow: 1 }}></Grid>
                                        <Grid item>
                                            <Switch
                                                edge="end"
                                                checked={copyObjectOptions.qmSettings}
                                            />
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton onClick={() => {
                                    setCopyObjectOptions((prev) => {
                                        prev.intervalControl = !prev.intervalControl;
                                        return { ...prev };
                                    })
                                }}>
                                    <Grid container direction={"row"} spacing={3} alignItems="center">
                                        <Grid item>
                                            <ChartBellCurve style={{ fontSize: 40 }} width={40} height={40} fill={"#000000"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography component="h4" variant="h4">Anwesenheitsplanung</Typography>
                                        </Grid>
                                        <Grid item style={{ flexGrow: 1 }}></Grid>
                                        <Grid item>
                                            <Switch
                                                edge="end"
                                                checked={copyObjectOptions.intervalControl}
                                            />
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton onClick={() => {
                                    setCopyObjectOptions((prev) => {
                                        prev.userServiceWorkers = !prev.userServiceWorkers;
                                        return { ...prev };
                                    })
                                }}>
                                    <Grid container direction={"row"} spacing={3} alignItems="center">
                                        <Grid item>
                                            <ManageAccounts style={{ fontSize: 40 }} width={70} height={70} fill={"#000000"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography component="h4" variant="h4">Servicekräfte-Zuweisung</Typography>
                                        </Grid>
                                        <Grid item style={{ flexGrow: 1 }}></Grid>
                                        <Grid item>
                                            <Switch
                                                edge="end"
                                                checked={copyObjectOptions.userServiceWorkers}
                                            />
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton onClick={() => {
                                    setCopyObjectOptions((prev) => {
                                        prev.notifications = !prev.notifications;
                                        return { ...prev };
                                    })
                                }}>
                                    <Grid container direction={"row"} spacing={3} alignItems="center">
                                        <Grid item>
                                            <Mail style={{ fontSize: 40 }} width={70} height={70} fill={"#000000"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography component="h4" variant="h4">Benachrichtigung</Typography>
                                        </Grid>
                                        <Grid item style={{ flexGrow: 1 }}></Grid>
                                        <Grid item>
                                            <Switch
                                                edge="end"
                                                checked={copyObjectOptions.notifications}
                                            />
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton onClick={() => {
                                    setCopyObjectOptions((prev) => {
                                        prev.materialStock = !prev.materialStock;
                                        return { ...prev };
                                    })
                                }}>
                                    <Grid container direction={"row"} spacing={3} alignItems="center">
                                        <Grid item>
                                            <LocalShipping style={{ fontSize: 40 }} width={70} height={70} fill={"#000000"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography component="h4" variant="h4">Materialwirtschaft</Typography>
                                        </Grid>
                                        <Grid item style={{ flexGrow: 1 }}></Grid>
                                        <Grid item>
                                            <Switch
                                                edge="end"
                                                checked={copyObjectOptions.materialStock}
                                            />
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>

                            <ListItem>
                                <ListItemButton onClick={() => {
                                    setCopyObjectOptions((prev) => {
                                        prev.qrCodeInformation = !prev.qrCodeInformation;
                                        return { ...prev };
                                    })
                                }}>
                                    <Grid container direction={"row"} spacing={3} alignItems="center">
                                        <Grid item>
                                            <QrCode2 style={{ fontSize: 40 }} width={70} height={70} fill={"#000000"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography component="h4" variant="h4">Qr-Code Information</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Alert severity="warning">
                                                <AlertTitle>Überschrieben wird:</AlertTitle>
                                                Qr-Code Information (Farbe, Größe, URL)
                                            </Alert>
                                        </Grid>
                                        <Grid item style={{ flexGrow: 1 }}></Grid>
                                        <Grid item>
                                            <Switch
                                                edge="end"
                                                checked={copyObjectOptions.qrCodeInformation}
                                            />
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton onClick={() => {
                                    setCopyObjectOptions((prev) => {
                                        prev.assignProperty = !prev.assignProperty;
                                        return { ...prev };
                                    })
                                }}>
                                    <Grid container direction={"row"} spacing={3} alignItems="center">
                                        <Grid item>
                                            <WindowOutlined style={{ fontSize: 40 }} width={70} height={70} fill={"#000000"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography component="h4" variant="h4">Merkmale zu Objekt zuweisen</Typography>
                                        </Grid>
                                        <Grid item style={{ flexGrow: 1 }}></Grid>
                                        <Grid item>
                                            <Switch
                                                edge="end"
                                                checked={copyObjectOptions.assignProperty}
                                            />
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>
                            <ListItem>
                                <ListItemButton onClick={() => {
                                    setCopyObjectOptions((prev) => {
                                        prev.isEnabledObject = !prev.isEnabledObject;
                                        return { ...prev };
                                    })
                                }}>
                                    <Grid container direction={"row"} spacing={3} alignItems="center">
                                        <Grid item>
                                            <Check style={{ fontSize: 40 }} width={70} height={70} fill={"#000000"} />
                                        </Grid>
                                        <Grid item>
                                            <Typography component="h4" variant="h4">Objekt Aktiviert</Typography>
                                        </Grid>
                                        <Grid item style={{ flexGrow: 1 }}></Grid>
                                        <Grid item>
                                            <Switch
                                                edge="end"
                                                checked={copyObjectOptions.isEnabledObject}
                                            />
                                        </Grid>
                                    </Grid>
                                </ListItemButton>
                            </ListItem>

                        </List>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <ObjectSelectionDataTable rows={objects} groups={groups} currentGroup={null}
                                selectedObjects={selectedObjects}
                                onSelectionChange={(selection: string[]) => {
                                    setSelectedObjects(objects.filter(x => selection.includes(x.id)));

                                }}
                            />
                        </Grid>


                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onClose}
                >
                    {t("myUnitsSettings.close")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={loading && <CircularProgress size={32} />}
                    endIcon={<Save />}
                    disabled={loading || selectedObjects.length < 1}
                    onClick={async () => {

                        setCount(selectedObjects.length);
                        setLoading(true);
                        for (let object of selectedObjects) {


                            if (copyObjectOptions.isEnabledObject) {
                                object.isActivatedEnabled = currentObject.isActivatedEnabled;
                            }
                            if (copyObjectOptions.qmSettings) {
                                object.qmSettings = currentObject.qmSettings;
                            }
                            if (copyObjectOptions.objectSettings) {
                                object.isQrCodeSignInForcedEnabled = currentObject.isQrCodeSignInForcedEnabled;
                                object.isQrCodeSignOutForcedEnabled = currentObject.isQrCodeSignOutForcedEnabled;
                                object.isQrCodeGroupSignInObjectLeaderForcedEnabled = currentObject.isQrCodeGroupSignInObjectLeaderForcedEnabled;
                                object.objectDisplayType = currentObject.objectDisplayType;
                            }
                            if (copyObjectOptions.intervalControl){
                                if (ObjectUnit.IsStandaloneLicenseValid(object)){
                                    object.intervalControl = currentObject.intervalControl;
                                }else{
                                    enqueueSnackbar("Keine Gültige Objektlizenz", {variant : 'error'});
                                }
                            }
                            if (copyObjectOptions.userServiceWorkers){
                                object.userServiceWorkerIds = currentObject.userServiceWorkerIds;
                            }
                            if (copyObjectOptions.notifications){
                                if (ObjectUnit.IsStandaloneLicenseValid(object)){
                                    object.notificationSettings = currentObject.notificationSettings;
                                    object.isDailyReportEnabled = currentObject.isDailyReportEnabled;
                                }else{
                                    enqueueSnackbar("Keine Gültige Objektlizenz", {variant : 'error'});
                                }

                            }
                            if (copyObjectOptions.materialStock){
                                object.notificationSettings.alarmEmailMaterialStock = currentObject.notificationSettings.alarmEmailMaterialStock;
                                let latestUserStock = await api.getLatestUserStockByObjectId(token, currentObject.id);
                                let latestUserStockOrigin = await api.getLatestUserStockByObjectId(token, object.id);
                                latestUserStock.groupId = "";
                                latestUserStock.restockGroupCount = 0;

                                latestUserStock.id = "";
                                latestUserStock.systemETag = "";
                                latestUserStock.userServiceWorkerId = "";
                                latestUserStock.createdTimestamp = new Date();
                                latestUserStock.stockActionDoneFromType = StockActionDoneFromType.PortalObjectSettings;
                                latestUserStock.objectId = object.id;
                                if (latestUserStockOrigin?.restockObjectCount){
                                    latestUserStock.restockObjectCount = latestUserStockOrigin.restockObjectCount + 1;
                                }

                                latestUserStock.materialStocks = latestUserStock.materialStocks.map(x=>{
                                    let originMaterial = latestUserStockOrigin?.materialStocks?.find(y=>x.materialId == y.materialId) ?? undefined;
                                    if (originMaterial){
                                        x.lastObjectStockAmount = originMaterial.currentObjectStockAmount;
                                        x.resetCountObject = originMaterial.resetCountObject + 1;
                                    }
                                    x.alarmEmailGroup = "";
                                    x.currentGroupStockAmount = 0;
                                    x.lastGroupStockAmount = 0;
                                    x.maximumGroupAmount = 0;
                                    x.minimumGroupAmount = 0;
                                    x.groupImages = [];
                                    x.groupPhotos = [];
                                    x.resetCountGroup = 0;
                                    return x;
                                })

                                await api.createUserStock(latestUserStock, token, enqueueSnackbar);


                            }
                            if (copyObjectOptions.qrCodeInformation) {
                                object.qrCodeInformation = currentObject.qrCodeInformation;
                            }
                            if (copyObjectOptions.assignProperty){
                                object.userPropertyIds = currentObject.userPropertyIds;
                            }

                            const updated = await api.putUserObjectUnit(object, token, enqueueSnackbar);
                            setCount((prev) => prev - 1);
                        }
                        setLoading(false);
                        onClose();
                    }}
                >
                    {loading ? `Noch ${count} Objekte zum Speichern` : `${selectedObjects.length} Objekte Speichern`}
                </Button>
            </DialogActions>
        </Dialog >
    );
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            marginTop: theme.spacing(1),
        },
    }),
);
