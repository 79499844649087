import React from 'react';
import { Button, CircularProgress, Grid, Paper, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Product } from '../../../models/Product';
import { useHistory } from 'react-router-dom';
import { getApiToken } from '../../../helpers/storage-management';
import { Add, AlarmOn, Edit, PhonelinkSetup, Print, QrCode, QrCode2 } from '@mui/icons-material';
import { ProductManagementPageEditingDialog } from '../ProductManagementPageEditingDialog';
import { ConfirmationDialog } from '../../dialogs/ConfirmationDialog';
import { api } from '../../../api';
import { useSnackbar } from 'notistack';
import { ProductManagementPageDataTable } from '../ProductManagementPageDataTable';
import { ObjectManagementPageDataTable } from './ObjectManagementPageDataTable';
import { ObjectManagementPageEditingDialog } from './ObjectManagementPageEditingDialog';
import { ObjectUnit } from '../../../models/ObjectUnit';
import { GroupLicenseTable, ObjectManagementGroups } from '../../../models/GroupLicenseTable';
import { getUserIdFromStorage } from '../../../helpers/common';
import { ServiceWorkerType, UserServiceWorker } from '../../../models/UserServiceWorker';
import { ObjectQrCodeDialog } from './ObjectQrCodeDialog';
import { LicenseBlack } from '../../../assets';
import { UserServiceWorkerSelectionForObjectsDialog } from './UserServiceWorkerSelectionForObjectsDialog';
import { ObjectGroupSettingsDialog } from './ObjectGroupSettingsDialog';
import { LinearProgressWithLabel } from '../../core/components/ProgressComponent';
import { ObjectSelectionQrCodePrintDialog } from '../portal/object/dialog/ObjectSelectionQrCodePrintDialog';
import { ObjectQrCodePrintManyDialog } from '../portal/object/dialog/ObjectQrCodePrintManyDialog';
import { ObjectManagementPageMassEditingDialog } from './ObjectManagementPageMassEditingDialog';
import { AddServiceWorkerObjectSessionDialog } from './objectUnit/AddServiceWorkerObjectSessionDialog';
import { GroupLicense } from '../../../models/GroupLicense';
import { LicenseType } from '../../../models/LicenseType';
import { PropertyType, UserProperty } from '../../../models/UserProperty';
import { useTranslation } from 'react-i18next';

export const ObjectManagementPage = () => {

    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const [token, setToken] = React.useState('');
    const [objects, setObjects] = React.useState<ObjectUnit[]>([]);
    const [addSettingDialogVisible, setAddSettingDialogVisible] = React.useState(false);
    const [selectedObject, setSelectedObject] = React.useState<ObjectUnit>();
    const [editSettingDialogVisible, setEditSettingDialogVisible] = React.useState(false);
    const [deleteConfirmationDialogVisible, setDeleteConfirmationDialogVisible] = React.useState(false);
    const [groups, setGroups] = React.useState<GroupLicenseTable[]>([]);
    const [userServiceWorkers, setUserServiceWorkers] = React.useState<UserServiceWorker[]>([]);
    const [userProperties, setUserProperties] = React.useState<UserProperty[]>([]);

    const [groupSelectionAssignServiceWorkerDialogVisible, setGroupSelectionAssignServiceWorkerDialogVisible] = React.useState(false);

    const [objectGroupSettingsDialogVisible, setObjectGroupSettingsDialogVisible] = React.useState(false);
    const [freeLicenses, setFreeLicenses] = React.useState<GroupLicense[]>([]);


    const [selectedObjects, setSelectedObjects] = React.useState<ObjectUnit[]>([]);
    const [editMassSettingDialogVisible, setEditMassSettingDialogVisible] = React.useState(false);

    const [loadingProgress, setLoadingProgress] = React.useState(0);
    const [valueBuffer, setValueBuffer] = React.useState(20);


    const [qrCodeDialogVisible, setQrCodeDialogVisible] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [isObjectSelectionForPrintVisible, setIsObjectSelectionForPrintVisible] = React.useState(false);
    const [isObjectQrCodePrintManyDialogVisible, setIsObjectQrCodePrintManyDialogVisible] = React.useState(false);
    const [selectedObjectsForQrCode, setSelectedObjectsForQrCode] = React.useState<ObjectUnit[]>([]);
    const {t} = useTranslation();

    const [isAddServiceWorkerObjectSessionDialogVisible, setIsAddServiceWorkerObjectSessionDialogVisible] = React.useState(false)

    const onRefresh = async () => {
        setLoading(true);

        const tempToken = getApiToken(history);
        const userId = getUserIdFromStorage();

        if (!tempToken || !userId) return;

        setToken(tempToken);
        setLoadingProgress(0);
        setValueBuffer(20);

        const tempObjects = await api.getUserObjectUnits(tempToken);
        setObjects(tempObjects);

        setLoadingProgress(20);
        setValueBuffer(60);

        let allGroupsTemp = await api.GetAllGroupsByUserIdAsync(tempToken, userId);
        setGroups(allGroupsTemp);

        setLoadingProgress(60);
        setValueBuffer(100);

        const tempServiceWorkers = await api.getServiceWorkers(tempToken);
        setUserServiceWorkers(tempServiceWorkers);

        const tempUserProperties = await api.getUserPropertiesByType(tempToken, PropertyType.Object);
        setUserProperties(tempUserProperties);

        setLoadingProgress(100);
        setValueBuffer(100);
        setLoading(false);

        const licenses = await api.getLicensesAsync(tempToken);
        setFreeLicenses(licenses.filter(el => !el.isUsed && el.type == LicenseType.ObjectNotificationLog));

    }

    React.useEffect(() => {

        (async () => {
            await onRefresh();


        })();

    }, [history])

    return (
        <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
                <LinearProgressWithLabel value={loadingProgress} valueBuffer={valueBuffer} />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h4">
                    Objektverwaltung
                </Typography>
            </Grid>
            <Grid container item direction="row" spacing={1}>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add />}
                        onClick={async () => {
                            setSelectedObject(ObjectUnit.CreateObject());
                            setAddSettingDialogVisible(true);
                        }}
                    >
                        Objekt hinzufügen
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        disabled={selectedObjects.length < 1}
                        color="primary"
                        startIcon={<Edit />}
                        onClick={async () => {
                            setEditMassSettingDialogVisible(true);
                        }}
                    >
                        {selectedObjects.length} Objekte bearbeiten
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<Print />}
                        startIcon={<QrCode />}
                        onClick={async () => {
                            setIsObjectSelectionForPrintVisible(true);
                        }}
                    >
                        Qr-Code der Objekte drucken
                    </Button>
                </Grid>
            </Grid>
            <Grid item container spacing={3}>
                <Grid item>
                    <Typography variant="h5">
                        <PhonelinkSetup /> Einheiten Zuweisen
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5">
                        <AlarmOn /> {t("object-sessions.objectRegistrations")}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5">
                        <Edit /> Bearbeiten
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5">
                        <QrCode2 /> Qr Code Anzeige
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h5">
                        <LicenseBlack /> Objekt Standalone Lizenz zuweisen
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Paper>
                    {loading &&
                        <Grid item>
                            <CircularProgress size={64} />
                        </Grid>
                    }
                    <ObjectManagementPageDataTable
                        onRefresh={onRefresh}
                        freeLicenses={freeLicenses}
                        userServiceWorkers={userServiceWorkers}
                        userProperties={userProperties}
                        rows={objects}
                        groups={groups}
                        onShowQrCode={async (row: ObjectUnit) => {
                            setSelectedObject(row);
                            setQrCodeDialogVisible(true);
                        }}
                        onEdit={async (row: ObjectUnit) => {
                            setSelectedObject(row);
                            setEditSettingDialogVisible(true);
                        }}
                        onDelete={async (row: ObjectUnit) => {
                            setSelectedObject(row);
                            setDeleteConfirmationDialogVisible(true);
                        }}
                        setSelectedObjects={setSelectedObjects}
                        onAddServiceWorkerObjectSession={async (row: ObjectUnit) => {
                            setSelectedObject(row);
                            setIsAddServiceWorkerObjectSessionDialogVisible(true);
                        }}

                    />

                </Paper>
            </Grid>

            <ObjectManagementPageEditingDialog
                userProperties={userProperties}
                freeLicenses={freeLicenses}
                token={token}
                objects={objects}
                visible={addSettingDialogVisible}
                setVisible={setAddSettingDialogVisible}
                setGroupSelectionObjectLeadersDialogVisible={setGroupSelectionAssignServiceWorkerDialogVisible}
                setObjectGroupSettingsDialogVisible={setObjectGroupSettingsDialogVisible}
                object={selectedObject}
                groups={groups}
                userServiceWorkers={userServiceWorkers}
                onSave={async (objectUnit: ObjectUnit) => {
                

                    const added = await api.createUserObjectUnit(objectUnit, token, enqueueSnackbar);

                    if (!added)
                        return;
                    setObjects([added, ...objects]);
                    onRefresh();
                }}
            />

            <ObjectManagementPageEditingDialog
                freeLicenses={freeLicenses}
                userProperties={userProperties}
                token={token}
                objects={objects}
                groups={groups}
                visible={editSettingDialogVisible}
                setGroupSelectionObjectLeadersDialogVisible={setGroupSelectionAssignServiceWorkerDialogVisible}
                setObjectGroupSettingsDialogVisible={setObjectGroupSettingsDialogVisible}
                setVisible={setEditSettingDialogVisible}
                object={selectedObject}
                userServiceWorkers={userServiceWorkers}
                onSave={async (object: ObjectUnit) => {


                    const updated = await api.putUserObjectUnit(object, token, enqueueSnackbar);

                    if (!updated) return;

                    setObjects(p => p.map((item: ObjectUnit) => item.id === updated.id ? updated : item));
                    onRefresh();


                }}
            />

            <ObjectManagementPageMassEditingDialog
                selectedObjects={selectedObjects} visible={editMassSettingDialogVisible} setVisible={setEditMassSettingDialogVisible} token={token} />

            {selectedObject &&
                <UserServiceWorkerSelectionForObjectsDialog onRefresh={onRefresh} open={groupSelectionAssignServiceWorkerDialogVisible} setOpen={setGroupSelectionAssignServiceWorkerDialogVisible} currentObject={selectedObject} objects={objects} serviceWorkerTypeToShow={[ServiceWorkerType.ObjectLeader, ServiceWorkerType.ServiceWorkerPlus]} />
            }

            {selectedObject &&
                <ObjectGroupSettingsDialog onRefresh={onRefresh} open={objectGroupSettingsDialogVisible} setOpen={setObjectGroupSettingsDialogVisible} currentObject={selectedObject} objects={objects} />
            }
            {selectedObject &&
                <AddServiceWorkerObjectSessionDialog open={isAddServiceWorkerObjectSessionDialogVisible} setOpen={setIsAddServiceWorkerObjectSessionDialogVisible} groups={groups} objects={objects} userServiceWorkers={userServiceWorkers} currentObject={selectedObject} />
            }
            <ObjectQrCodeDialog
                visible={qrCodeDialogVisible}
                setVisible={setQrCodeDialogVisible}
                object={selectedObject}
                setObject={setSelectedObject}
            />

            <ConfirmationDialog
                visible={deleteConfirmationDialogVisible}
                title="Sind Sie sicher, dieses Objekt zu löschen ?"
                onClose={() => {
                    setDeleteConfirmationDialogVisible(false);
                }}
                onConfirmed={async () => {
                    if (!selectedObject) return;

                    await api.deleteUserObjectUnit(selectedObject.id, token, enqueueSnackbar);

                    setObjects(objects.filter(x => x.id !== selectedObject.id));
                    setDeleteConfirmationDialogVisible(false);
                    onRefresh();
                }}
            />

            <ObjectSelectionQrCodePrintDialog open={isObjectSelectionForPrintVisible} setOpen={setIsObjectSelectionForPrintVisible} groups={groups}
                onFinish={(selectedObjectsForQrCode: ObjectUnit[]) => {
                    setSelectedObjectsForQrCode(selectedObjectsForQrCode);
                    setIsObjectQrCodePrintManyDialogVisible(true);
                }}
                objects={objects} />
            <ObjectQrCodePrintManyDialog visible={isObjectQrCodePrintManyDialogVisible} setVisible={setIsObjectQrCodePrintManyDialogVisible} objects={selectedObjectsForQrCode} />
        </Grid>
    );
};